.MessageVideo {
	width: 35%;
	min-width: 40rem;

	& video {
		width: 100%;
		min-width: 13rem;
		border-radius: 10px; }

	.ExpandFile {
		position: absolute;
		top: 1.5rem;
		right: 1.8rem;
		box-shadow: var(--shadow-light);
		opacity: 0;
		border-radius: .8rem;
		transition: all .2s;
		height: 3rem;
		width: 3rem;
		z-index: 2; }

	&:hover .ExpandFile {
		opacity: 1;
		transition: all .2s; } }
