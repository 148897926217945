.Textarea {
	border: 1px solid var(--color-grey);
	border-radius: .6rem;
	resize: none;
	outline: none;
	padding: 1rem;
	font-family: 'Hind' !important;
	color: var(--color-dark);
	font-size: 16px;

	&:disabled {
		color: var(--color-dark-grey-4); }

	&:focus {
		border: 1px solid var(--color-primary); } }
