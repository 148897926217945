.ChatEmail {
	display: flex;
	align-items: center;
	height: 100%;
	overflow-x: hidden;

	&__input {
		width: 100%;
		height: 100%;
		max-height: 14rem;
		overflow-y: auto;
		overflow-x: hidden;
		outline: none;
		font-size: 1.6rem;
		color: var(--color-dark);
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto;
		word-break: break-word;
		padding: 1rem;
		white-space: pre-wrap;
		border: none;
		resize: none;
		font-family: 'Hind' !important;

		&:hover:not(.ChatEmail__input--disabled) {
			cursor: text; }

		&:empty::before {
			content: attr(placeholder);
			color: var(--color-grey);
			display: block; } } }
