.AgentTaskItem {
	display: grid;
	grid-template-columns: min-content min-content 1fr min-content;
	grid-gap: 1.5rem;
	padding: 1.5rem 1rem;
	border-bottom: 1px solid var(--color-light-grey);
	cursor: pointer;
	position: relative;
	background-color: var(--color-white);

	&--active {
		background: var(--color-light-grey);

		& .AgentTaskItem__arrow {
			display: block; } }

	&:hover {
		background: var(--color-light-grey); }

	&__time {
		font-size: 1.6rem;
		font-weight: 500;
		color: var(--color-dark); }

	&--expiring {
		margin-bottom: 1.5rem;
		background: var(--color-light-orange);
		border: 1px solid var(--color-orange);
		border-left-width: 5px;
		border-radius: 5px;

		&:hover {
			opacity: .8; }

		& .AgentTaskItem__title {
			color: var(--color-orange); }

		& p.AgentTaskItem__data {
			color: var(--color-dark); } }

	&__title {
		font-size: 1.3rem;
		color: var(--color-dark);
		margin-bottom: 1rem; }

	&__warning {
		font-size: 1.1rem;
		font-size: 500;
		color: var(--color-orange);
		margin-bottom: 0.5rem; }

	&__data {
		font-size: 1rem;
		font-weight: 400;
		margin-bottom: 0.4rem;
		color: var(--color-dark-grey); }

	&__status {
		display: flex;
		align-items: center;
		padding-right: 2.5rem; }

	&__arrow {
		display: none;
		position: absolute;
		top: 50%;
		right: 1.5rem;
		margin-top: -0.5rem;
		transform: rotate(-90deg);

		&__arrow > svg {
			width: 1rem;
			height: 0.625rem; } } }

@media only screen and (max-width:800px) {
	.AgentTaskItem {
		grid-auto-flow: row;
		grid-template-columns: auto; } }
