.TaskSidebar {
	width: 25vw;
	min-width: 40rem;
	border-left: 1px solid var(--color-light-grey);
	height: 100%;
	overflow: auto;
	position: relative;

	&__empty {
		color: var(--color-dark);
		text-align: center;

		& h3 {
			color: var(--color-blue); }

		& > h3 {
			margin-top: 2rem;
			font-size: 1.6rem;
			font-weight: 500; }

		& > p {
			margin-top: 0.5rem;
			font-size: 1.2rem; }

		&__image {
			width: 10rem;
			height: 10rem;
			margin: 2rem auto 0 auto;
			background-color: var(--color-grey);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			& > svg {
				fill: var(--color-white);
				width: 4rem;
				height: 4.8rem; } } }

	&__close {
		position: absolute;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		width: 3rem;
		height: 3rem;
		right: 4rem;
		top: 3rem;
		outline: none;
		cursor: pointer;
		z-index: 10;

		& svg {
			width: 1.8rem;
			height: 1.8rem; } } }
