.ClientBoxWrapper {
	display: flex;
	position: relative;
	border-left: 1px solid #CBD5E1;
	&__button {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #898B94;
		position: absolute;
		top: calc(50vh - 82px);
		left: -12px;
		z-index: 30;
		filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.20));
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&--oppened {
			& svg {
				transform: rotate(270deg) !important; } }
		& svg {
			transform: rotate(90deg); } }
	.ClientBox {
		display: flex;
		flex-direction: column;
		width: 20vw;
		min-width: 32rem;
		height: calc(100vh - 72px);
		background-color: var(--color-white);
		position: relative;
		transition: .6s;

		@media only screen and (min-width: 1200px) {
			min-width: 37rem; }

		&.retracted {
			min-width: 6rem;
			width: 6rem;
			flex-direction: row;
			transition: 0s;
			overflow-x: hidden;
			& .ClientBox__navigation {
				padding: 0; } }
		&__navigation {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0 2rem;
			border-bottom: 1px solid #CBD5E1; }
		&__content {
			display: contents; } } }
