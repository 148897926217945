.Loader {
	display: flex;
	justify-self: center;
	justify-content: center;
	align-items: center;

	&__img {
		width: 3rem;
		height: 3rem; }

	&__img--small {
		width: 2.2rem;
		height: 2.2rem; } }
