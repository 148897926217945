.PhoneInteraction {
	display: grid;
	width: 100%;
	height: 100%;
	align-content: center;
	text-align: center;
	gap: 2rem;
	justify-items: center;

	& p {
		color: var(--color-dark); }

	&__time {
		font-size: 3.6rem; }

	&__users {
		display: grid;
		grid-template-columns: repeat(4, min-content);
		gap: .3rem; }

	&__actions {
		display: grid;
		grid-auto-flow: column;
		gap: 1rem; } }
