.CommonInput {
	display: grid;
	gap: 1rem;

	& > p:first-of-type {
		font-weight: 600;
		color: var(--color-dark-grey); }

	&__input {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr min-content;
		position: relative;
		align-items: center;

		& > input {
			padding: .65rem 1rem .65rem 3.5rem !important;
			border: 1px solid var(--color-grey);
			font-family: 'Hind' !important;
			font-size: 1.4rem;
			font-weight: 400;
			outline: none;
			color: var(--color-dark);
			border-radius: .6rem;
			background-color: var(--color-white);

			&:focus {
				border: 1px solid var(--color-primary); }

			&:disabled {
				color: var(--color-grey) !important;
				background-color: var(--color-light-grey);
				border: var(--color-light-grey);

				&:hover {
					cursor: not-allowed; } } } }

	&--clear {
		& svg {
			display: none; }

		& > input, & > input:focus {
			border: none;
			border-radius: 0; } }

	&__image {
		position: absolute;
		left: 1rem;
		display: flex;
		align-items: center;

		& > svg, & img {
			height: 1.6rem;
			width: 1.6rem; }

		& svg path {
			fill: var(--color-primary); } } }
