.NavBarItem {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 5px;
	position: relative;
	cursor: pointer;
	outline: none;
	color: #FFF;
	position: relative;
	font-weight: 700;
	white-space: nowrap;
	&--active {
		& > p {
			border-radius: 2px;
			padding: 5px 25px;
			font-weight: 700;
			font-size: 14px;
			border: 1px solid #FFF !important;
			color: #FFF; } }

	&:not(.NavBarItem--active):hover {
		& > p {
			background: hsla(0,0%,100%,.16); } }

	& > p {
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		gap: .8rem;
		font-size: 16px;
		border-radius: 2px;
		padding: 0 16px;
		height: 32px;
		transition: all .3s;
		border: 1px solid transparent; }

	&__counter {
		display: flex;
		justify-content: center;
		align-items: center;
		right: 0;
		width: 1.4rem;
		height: 1.4rem;
		border-radius: 50%;
		font-size: 0.38rem;
		color: var(--color-white);
		background: var(--color-red); } }
