.voiceRecorder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__container {
        display: flex;
        height: 70px;
        justify-content: space-between;
        align-items: center; }
    &__buttons {
        display: flex;
        min-width: 100px;
        flex-direction: row;
        justify-content: space-evenly; } }
