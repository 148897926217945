.ChatText {
	display: flex;
	height: 100%;
	overflow-x: hidden;

	&__input {
		width: 100%;
		max-height: 25rem;
		overflow-y: auto;
		overflow-x: hidden;
		outline: none;
		height: 36px;
		font-size: 1.6rem !important;
		font-family: 'Hind' !important;
		color: var(--color-dark);
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto;
		word-break: break-word;
		padding: 0 0 0 1rem;
		white-space: pre-wrap;
		resize: none;
		border: none;

		&--disabled {
			color: var(--color-light);
			cursor: not-allowed; } } }
