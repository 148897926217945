.ChatMail {
	background-color: var(--color-light);
	&__wrapper {
		min-height: 10rem;
		padding: 0 1rem 0 1rem;
		margin: .5rem 1rem 1rem 1rem;
		background-color: #fff;
		border: solid 2px #CBD5E1;
		border-radius: 10px;

		.TagItem {
			width: fit-content; }

		& input {
			width: 100%;
			padding: 1rem;
			border: none;
			outline: none;
			font-size: 1.6rem;
			color: var(--color-dark); }

		&__input {
			display: flex;
			align-items: center;
			border-bottom: 1px solid var(--color-light-grey);
			background-color: var(--color-white);
			font-size: 1.6rem;
			&:first-of-type {
				padding-left: 1rem; } }

		& .ChatEmail {
			display: grid;
			align-items: flex-start;
			min-height: 15rem;
			background-color: var(--color-white); }

		&__main {
			background-color: var(--color-white);
			cursor: text;

			&--disabled .ChatEmail {
				min-height: 3rem; } }

		&__actions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 1rem;

			&__icons {
				display: flex;
				align-items: center; } } } }

.Attachments {
	display: flex;
	grid-auto-flow: column;
	padding: 0.6rem 1rem 1.6rem 1rem; }

@media only screen and (max-width:800px) {
	.ChatMail__wrapper__main {
		padding: 0; }

	.ChatMail__wrapper__actions {
		gap: 0;
		padding: 0; }

	.ChatMail__wrapper .ChatEmail {
		min-height: 12rem; }

	.Attachments {
		padding: 0 1rem 0 1rem; } }
