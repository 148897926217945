.FontSize {
	max-width: 460px;
	&__description {
		margin: 30px 0 25px 0;
		& p {
			font-weight: 400; } }
	&__header {
		margin-bottom: 15px;
		position: relative;
		& p {
			line-height: 20px; }
		&:before {
			content: '';
			height: 1px;
			width: calc(100% + 40px);
			background-color: #ddd;
			position: absolute;
			left: -20px;
			bottom: -15px; } }
	&__actions {
		display: flex;
		justify-content: space-between; }
	&__preview {
		margin: 40px 0 50px 0;
		&__text {
			font-weight: 600; }
		&__bubble {
			display: flex;
			padding: 12px;
			height: 50px;
			background: #0073E6;
			border-radius: 10px;
			color: #fff;
			align-items: center;
			margin-top: 10px;

			&[data-size="small"] {
				font-size: 14px; }
			&[data-size="medium"] {
				font-size: 16px; }
			&[data-size="big"] {
				font-size: 20px; } } }

	input[type="range"] {
		display: block;
		padding: 0;
		background: transparent;
		font-size: 1em;
		cursor: pointer;
		width: 100%;
		position: relative;
		height: 2px;

		&,
		&::-webkit-slider-runnable-track,
		&::-webkit-slider-thumb {
			-webkit-appearance: none; }

		&::after {
			content: "";
			width: 2px;
			height: 10px;
			background: var(--color-light-grey);
			position: absolute;
			right: 6px;
			bottom: 3px; }

		&::before {
			content: "";
			width: 2px;
			height: 10px;
			background: var(--color-light-grey);
			position: absolute;
			left: 6px;
			bottom: 3px; }

		&[data-size="small"] {
			background: linear-gradient(to right, #fff 1.2%, var(--color-light-grey) 1.2%, var(--color-light-grey) 98.7%, #fff 98.7%); }
		&[data-size="medium"] {
			background: linear-gradient(to right, #fff 1.2%, #2c4fd8 1.2%, #2c4fd8 50%, var(--color-light-grey) 50%, var(--color-light-grey) 98.7%, #fff 98.7%); }
		&[data-size="big"] {
			background: linear-gradient(to right, #fff 1.2%, #2c4fd8 1.2%, #2c4fd8 98.7%, #fff 98.7%); }

		&::-ms-track {
			border: none;
			color: transparent; }

		&::-webkit-slider-thumb {
			border: none;
			border-radius: 50%;
			box-sizing: border-box;
			border: none;
			width: 14px;
			height: 14px;
			background: #2c4fd8;
			position: relative;
			z-index: 10;
			margin-top: -.5px; }

		&::-ms-tooltip {
			display: none; }

		&:focus {
			outline: none; } }

	&__range {
		margin-top: 13px;
		&__list {
			margin: 5px 4px 0 4px;
			padding: 0;
			display: flex;
			justify-content: space-between;
			position: relative;

			& .selected {
				font-weight: 600; }

			&::before {
				content: "";
				width: 2px;
				height: 10px;
				background: var(--color-light-grey);
				position: absolute;
				left: calc(50% - 1px);
				bottom: 40px; }

			& div {
				display: flex;
				align-items: flex-end;
				cursor: pointer;
				font-weight: 400;
				&:nth-child(1) {
					font-size: 14px; }
				&:nth-child(2) {
					font-size: 16px;
					margin-left: 5px; }
				&:nth-child(3) {
					font-size: 20px; } }

			li {
				flex: 2;
				font-size: 16px;
				text-align: center; }

			li.selected {
				font-weight: bold;
				color: #323232; } } } }
