.CallWave {
	position: relative;


	&__bar {
		display: inline-block;
		width: .7rem;
		height: 7rem;
		background: #ED6161;
		border-radius: 10px;

		&:not(:last-of-type) {
			margin-right: 3px; }

		&.is-first {
			animation: pulse-1 1.5s linear infinite; }

		&.is-second {
			animation: pulse-2 1.5s linear 0.3s infinite; }

		&.is-third {
			animation: pulse-3 1.5s linear 0.6s infinite; } }


	&--stop	* {
		animation: none !important;
		height: 1rem; } }


@keyframes pulse-1 {
	0% {
		transform: scaleY(1); }
	25% {
		transform: scaleY(0.6); }
	50% {
		transform: scaleY(0.4); }
	100% {
		transform: scaleY(1); } }

@keyframes pulse-2 {
	0% {
		transform: scaleY(0.8); }
	25% {
		transform: scaleY(0.4); }
	50% {
		transform: scaleY(0.6); }
	100% {
		transform: scaleY(0.8); } }

@keyframes pulse-3 {
	0% {
		transform: scaleY(0.6); }
	25% {
		transform: scaleY(0.2); }
	50% {
		transform: scaleY(0.4); }
	100% {
		transform: scaleY(0.6); } }
