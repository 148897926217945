.ReceptiveCallModal {
	display: grid;
	grid-template-rows: 26rem 1fr;
	padding: 0;

	&__user {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		align-items: center;
		background-color: var(--color-light-grey);
		color: var(--color-dark);
		font-weight: 600;
		padding: 5rem 0 2rem 0;

		& .AvatarName {
			height: 13rem;
			width: 13rem;
			animation: expanding 2s infinite; } }

	&__call {
		background-color: var(--color-white);
		display: grid;
		gap: 1.5rem;
		padding: 2rem;
		color: var(--color-dark-grey);
		text-align: center;

		&__actions {
			display: grid;
			grid-auto-flow: column;
			justify-content: center;
			gap: 4rem;

			&__item {
				display: grid;
				gap: 1rem;
				justify-items: center;
				color: var(--color-dark-grey);

				& .IconButton {
					width: 5.7rem;
					height: 5.7rem; } } } } }

@keyframes expanding {
	0% {
		transform: scale(1); }

	50% {
		transform: scale(1.05); }

	100% {
		transform: scale(1); } }
