.Resize {
	width: 100%;
	height: 10px;
	cursor: row-resize;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	background-color: var(--color-white);
	&--disable {
		cursor: auto;
		& span {
			background-color: #FFF; } }
	@media only screen and (max-width:800px) {
		display: none; }

	&__dot {
		height: 3px;
		width: 3px;
		border-radius: 50%;
		margin: 0 3px;
		background-color: #CBD5E1; } }

.profani-message {
	padding: 8px 24px;
	font-size: 14px;
	font-weight: 400;
	height: fit-content;
	margin: 0 10px 0px 10px;
	& i {
		font-size: 14px !important;
		margin-bottom: 2px; } }

.ChatInput {
	max-height: calc(100vh - 280px);
	background-color: var(--color-light);
	position: relative;
	z-index: 11;
	margin-bottom: 1rem;
	&__wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 9rem;
		padding: 0 1rem 0 1rem;
		margin: 0 1rem 0 1rem;
		background-color: #fff;
		border: solid 2px #CBD5E1;
		border-radius: 10px;

		&--hasProfanyText {
			border: solid 2px #E02D19;
			border-radius: 0px 0px 10px 10px; }

		&--internal-message {
			border: solid 2px #FFD5B7;
			background-color: #FFEBDC;
			& .ChatInput__wrapper__header {
				display: flex; }
			& .Resize {
				visibility: hidden; }

			& .Resize, & textarea {
				background-color: #FFEBDC; } }
		&__header {
			display: none;
			color: #CF620D;
			font-size: 16px;
			font-weight: 500;
			background: linear-gradient(0deg, #FFD5B7 0%, #FFD5B7 100%), #121212;
			border-radius: 8px 8px 0px 0px;
			width: calc(100% + 20px);
			margin-left: -10px;
			padding: 0 12px;
			height: 36px;
			align-items: center;
			& svg {
				margin-bottom: 3px;
				margin-right: 8px;
				& path {
					fill: #CF620D; } } }
		&__voice-recorder {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8px; }

		& .separator {
			width: 1px;
			height: 24px;
			margin: 0 12px;
			background-color: #94A3B8; }

		&__input {
			height: 100%;
			& textarea {
				font-size: 16rem; } }

		&__actions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 1rem;
			&__icons {
				display: flex;
				align-items: center;

				&__cancel {
					cursor: pointer;
					font-size: 16px;
					color: var(--color-primary);
					transition: all 0.5s ease-in-out; } } }

		&__emojiPicker .emoji-mart {
			position: relative;
			width: 100% !important;
			z-index: 120; }

		& input {
			border: none; } } }

@media only screen and (max-width:800px) {
	.ChatInput__wrapper__actions__emoji {
		display: none; } }
