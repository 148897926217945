.MergeCustomer {
	display: grid;
	grid-template-rows: min-content 1fr min-content;
	gap: 3rem;
	padding: 3rem 2rem;
	position: relative;


	& svg {
		height: 1.5rem;
		width: 1.5rem; }


	&__info {
		display: grid;
		gap: 2rem; }


	&__options {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: 1fr;
		justify-items: center;
		margin-top: 3rem; }


	&__header p {
		&:nth-child(1) {
			font-size: 2rem;
			margin-bottom: 2rem;
			color: var(--color-dark); }

		&:nth-child(2), &:nth-child(3) {
			font-size: 1.4rem;
			color: var(--color-dark);
			overflow-wrap: break-word;
			word-wrap: break-word;
			hyphens: auto;
			word-break: break-word;
			margin-bottom: .5rem; }

		&:nth-child(3) {
			font-style: italic;
			font-size: 1.2rem; } }


	&__field {
		display: grid;
		gap: 1rem;
		text-align: left;
		color: var(--color-dark);

		& p {
			font-size: 1.5rem; }

		&__values {
			display: grid;
			grid-auto-flow: column;
			grid-auto-columns: 1fr;
			font-size: 1.4rem;
			box-shadow: var(--shadow-light);
			border-radius: .7rem;
			overflow: hidden;
			border: 1px solid var(--color-grey); } }


	&__emptyInfo {
		display: grid;
		gap: 2rem;
		justify-items: center;
		color: var(--color-dark);
		font-size: 2rem;

		& div {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--color-grey);
			border-radius: 50%;
			height: 10rem;
			width: 10rem; }

		& svg {
			height: 6rem;
			width: 6rem; }

		& path {
			fill: var(--color-white); } } }


.CheckName {
	display: flex;
	cursor: pointer;
	outline: none;
	color: var(--color-dark);
	padding: 1.5rem 1rem;
	justify-content: center;
	min-width: 0;

	& p {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap; }

	&--selected {
		background-color: var(--color-primary);
		color: var(--color-white); } }
