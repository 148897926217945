.AgentHistory {
	display: grid;
	grid-template-rows: min-content 1fr;
	gap: 1rem;

	& > .CommonInput {
		margin: 1.5rem 1.5rem 0 1.5rem; }

	&__list {
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		position: absolute;
		overflow-y: auto;
		overflow-x: hidden;

		&__wrapper {
			height: calc(100vh - 140px);
			position: relative; } }

	&__interactions > p {
		font-size: 1.6rem;
		text-align: center;
		padding-top: 1rem;
		color: var(--color-dark); } }
