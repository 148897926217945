.Backdrop {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	background-color: var(--backdropColor);
	width: 100%;
	height: 100%;
	z-index: 100;
	outline: none;

	&__closeAction {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100; }

	&--higher {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 111; }

	&--clear {
		background-color: transparent; }

	&--blur {
		backdrop-filter: blur(10px); } }
