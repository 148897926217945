.FileUploadConfirmation {
	&__wrapper {
		display: grid;
		align-items: center;
		grid-auto-rows: 1fr;
		font-size: 1.6rem;
		padding: 0;
		&__content {
			padding: 20px;
			max-width: 700px;
			&__file {
				margin: 20px 0;
				position: relative;
				display: flex;
				justify-content: center;
				&__default {
					display: flex;
					justify-content: center; }

				& video, & img {
					width: 100%;
					max-width: 100%;
					min-height: 70px; }
				&__icon {
					position: absolute;
					right: 10px;
					top: 10px; }
				& a svg {
					position: relative;
					width: 2.2rem;
					height: 2.2rem;
					border-radius: .3rem; } }
			&__actions {
				display: flex;
				justify-content: space-between;
				& button + button {
					margin-left: 20px; } } } } }

@media only screen and (max-width:800px) {
	.FileUploadConfirmation {
		& .Modal {
			width: auto;
			height: auto;
			max-width: 95%; }
		&__wrapper {
			width: auto;
			height: auto;
			border-radius: 0.8rem !important;
			&__content {
				padding: 20px; } } } }
