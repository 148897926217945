.Login {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	& .Logo {
		padding-left: 0; }

	&__box {
		display: grid;
		border-radius: .5rem;
		padding: 3rem 2.5rem;
		background-color: var(--color-white);
		z-index: 100;
		gap: 2rem; }

	&__header {
		display: grid;
		grid-auto-flow: column;
		justify-content: space-between;
		grid-auto-rows: min-content; }

	&__image {
		display: grid;
		width: 10rem;
		height: 10rem;
		justify-self: center;
		align-items: center;
		justify-content: center;
		background-color: var(--color-grey);
		border-radius: 50%;
		display: none;

		& svg {
			height: 5rem;
			width: 5rem; }

		& path {
			fill: var(--color-white); } }

	&__welcome {
		display: grid;
		align-content: end;
		gap: 1.5rem;
		color: var(--color-dark);

		& p {
			&:first-of-type {
				font-size: 1.3rem; }

			&:last-of-type {
				font-size: 2.4rem;
				color: var(--color-dark); } } }

	&__form, &__auth0Error {
		display: grid;
		grid-auto-rows: min-content;
		align-content: end;
		gap: 1.5rem;

		& .ErrorMessage {
			font-size: 1.3rem; }

		& .ButtonError {
			color: var(--color-dark); }

		& .Button {
			width: 100%; }

		&__error {
			color: var(--color-red);
			font-size: 1.2rem;
			height: 2rem; }

		& > button:last-of-type {
			display: flex;
			justify-self: right;
			width: auto; } }

	&--color {
		& .Button:first-of-type {
			background-color: var(--color-primary); }

		& .Login__background_1 {
			& path {
				fill: var(--color-primary); }

			& rect {
				fill: var(--color-primary);
				opacity: 0.7; } } }

	&--zenvia .Button:first-of-type {
		background-color: var(--color-light-blue); }

	&__background_1 {
		display: flex;
		align-items: center;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;

		& svg {
			height: 100%; }

		& rect {
			fill: var(--color-black);
			opacity: 1; }

		& path {
			fill: var(--color-black);
			opacity: 1; } }

	&__background_2 {
		display: flex;
		justify-content: flex-end;
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;

		& svg path {
			height: 100%; }

		& path {
			fill: var(--color-black); } } }

@media only screen and (max-width:800px) {
	.Login__box {
		width: 100%;
		height: 100%;
		border-radius: none;
		padding: 6rem 2rem; } }
