.Transfer {
	background-color: var(--color-white);
	margin: .5rem 0;
	min-width: 40rem;

	&__info {
		font-size: 1.6rem; }

	& > p {
		color: var(--color-dark); }

	&__actions {
		width: 100%;
		display: flex;
		justify-content: space-between;

		& button {
			height: 48px;
			width: 49%;
			cursor: pointer;
			border-radius: 2px;
			font-weight: 700;
			font-size: 16px; } }

	&__message {
		font-size: 13px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 15px 0 10px 0;

		& span + span {
			color: var(--color-dark-grey); } } }
