.UpdateAvatar {
	position: relative;


	& .IconButton {
		height: 2.5rem;
		width: 2.5rem;

		& svg {
			height: 1.5rem;
			width: 1.5rem; } }


	&__add {
		cursor: pointer;

		& input {
			display: none; } }


	&__icon {
		position: absolute;
		bottom: .4rem;
		right: .4rem; } }
