.ClientSearchItemField {
	display: grid;
	gap: .3rem;


	& p {
		&:first-of-type {
			color: var(--color-dark); }

		&:last-of-type {
			color: var(--color-dark-grey); } } }
