.AvatarQuantity {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.6rem;
	color: var(--color-white);
	font-weight: 600;
	border-radius: 50%;
	overflow: hidden;
	width: 5rem;
	height: 5rem;
	border: 3px solid var(--color-grey);
	background-color: var(--color-grey);
	position: relative;

	& > * {
		transform: translate(-.2rem); } }
