.ReceivingWhatsappCall {
	display: flex;
	background-color: #DEDBFE;
	height: 60px;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	z-index: 1;
	width: 100%;
	&--title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 8px;
		color: #393946; }
	&--buttons {
		display: flex;
		gap: 20px;
		margin-right: 34px;
		background: transparent;
		button {
			background: transparent;
			border: none;
			padding: 0;
			margin: 0;
			cursor: pointer; } } }
