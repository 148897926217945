.Card {
	border-radius: .8rem;
	box-shadow: var(--shadow-notification);
	background-color: var(--color-white);
	padding: 2rem;
	overflow: hidden;

	&-isDark {
		background-color: #353535;
		color: var(--color-white);
		border-radius: .8rem;
		box-shadow: var(--shadow-notification);
		padding: 2rem;
		overflow: hidden; } }
