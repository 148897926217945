.SelectOptions {
	background-color: var(--color-blue);
	position: absolute;
	border-radius: .5rem;
	box-shadow: var(--shadow-light);
	z-index: 10;
	top: 4rem;

	& div {
		&:first-of-type {
			padding-top: 1rem; }

		&:last-of-type {
			padding-bottom: 1rem; } }

	&::before {
		content: '';
		width: 1rem;
		height: 1rem;
		top: -.3rem;
		left: .8rem;
		background-color: var(--color-blue);
		position: absolute;
		transform: rotate(45deg);
		z-index: 1; } }
