.CheckBox {
	&__label {
		width: 2.1rem !important;
		height: 2.1rem !important;
		border-radius: .8rem;
		border: .2rem solid var(--color-grey);
		cursor: pointer;
		outline: none;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		& svg {
			width: .8rem;
			fill: var(--color-white);
			stroke: var(--color-white);
			stroke-width: .3rem; }

		&:hover {
			border: .2rem solid var(--color-primary); } }


	& input {
		display: none;

		&:checked + .CheckBox__label {
			background-color: var(--color-primary);
			border: none; } } }
