.TaskNotification {
	display: grid;
	gap: 3rem;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	right: 3rem;
	bottom: 3rem;
	z-index: 300;
	box-shadow: var(--shadow-light);
	background: var(--color-orange-1);
	border: 1px solid var(--color-orange-dark-1);
	border-left-width: 5px;
	border-radius: .5rem;
	overflow: hidden;
	padding: 2rem;


	&__header {
		display: flex;
		justify-content: space-between;
		color: var(--color-dark);
		height: 2.5rem;

		& > p {
			align-self: flex-end; }

		& > .IconButton {
			width: auto;
			height: auto;
			align-self: flex-start;
			transform: translate(.8rem, -.8rem);

			& svg {
				height: 1.2rem;
				width: 1.2rem; }

			& path {
				fill: var(--color-dark); } } }


	&__actions {
		display: grid;
		grid-auto-flow: column;
		gap: 3rem; }


	& .Button {
		width: max-content;
		min-width: auto;
		background-color: transparent;
		color: var(--color-orange-dark-2);
		padding: 0; } }
