.Notification {
	display: grid;
	gap: 2rem;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	align-items: center;
	min-width: 30rem;
	position: relative;


	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3.7rem;
		height: 3.7rem;
		border-radius: 50%;


		&--success {
			background-color: var(--color-green); }

		&--warning {
			background-color: var(--color-orange); }

		&--error {
			background-color: var(--color-red); }

		& svg {
			height: 2rem;
			width: 2rem; }

		& path {
			fill: var(--color-white); } }


	&__close {
		position: absolute;
		top: 0;
		right: 0;
		width: 1.2rem;
		height: 1.2rem;
		cursor: pointer;

		&__close svg {
			width: 100%;
			height: 100%; } }


	&__content {
		color: var(--color-dark-grey);

		& p:first-of-type {
			font-weight: 600;
			color: var(--color-dark); } } }


@media only screen and (max-width:800px) {
	.Notification {
		padding: 1rem;
		border-radius: .8rem;
		min-width: auto;

		&__content--responsive {
			word-wrap: break-word;
			word-break: break-all; } } }

@media only screen and (max-width:400px) {
	.Notification__content {
		max-width: 20rem; } }

@media only screen and (max-width:330px) {
	.Notification__content {
		max-width: 16rem; } }

