.MessageImage {
	width: 45%;
	min-width: 21rem;
	max-width: 400px;

	& img {
		width: 100%; }

	.ExpandFile {
		position: absolute;
		top: 1.5rem;
		right: 1.8rem;
		box-shadow: var(--shadow-light);
		opacity: 0;
		border-radius: .8rem;
		transition: all .2s;
		height: 3rem;
		width: 3rem; }

	&:hover .ExpandFile {
		opacity: 1;
		transition: all .2s; } }
