.ChatHeader {
	display: grid;
	grid-auto-flow: column;
	padding: 1.2rem 2.5rem 1.2rem 2rem;
	color: var(--color-dark);
	border-bottom: 1px solid #CBD5E1;
	gap: 1rem;
	background-color: var(--color-white);

	&__user {
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		justify-content: start;
		gap: 1rem;

		&__avatar {
			width: 4.8rem;
			height: 4.8rem;
			font-size: 1.6rem;
			background-color: #E2E8F0;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			& svg, & img {
				width: 2rem;
				height: 2rem; } }

		&__info {
			min-width: 0;
			& p {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 1.8rem !important;
				line-height: 22px;

				&:first-of-type {
					font-weight: 600; }

				&:not(:first-of-type) {
					font-weight: 400;
					font-size: 1.6rem !important; } } } }
	&__actions {
		display: grid;
		grid-auto-flow: column;
		gap: 1rem;
		justify-content: end;
		align-items: center;

		&__active-button {
			& svg {
				width: 20px;
				height: 20px;
				margin-left: .5rem; } }

		&__button {
			width: 62px;
			border: 1px solid var(--color-dark);
			border-radius: 2px;
			&:hover {
				border: 1px solid var(--color-primary); } }
		& div:hover .Tip {
			display: block;
			transition: all .2s; } } }

@media only screen and (min-width: 320px) and (max-width: 360px) {
	.ChatHeader {
		padding: 1rem; }

	.ChatHeader__user__info--responsive {
		max-width: 6.5rem; }

	.ChatHeader__user__info p:first-of-type {
		margin-bottom: 0; } }

@media only screen and (min-width: 360px) and (max-width: 395px) {
	.ChatHeader__user__info--responsive {
		max-width: 11.5rem; } }
