.ActionHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color-dark);
	padding: 1rem;
	font-weight: 600;
	z-index: 10;
	&--invalid div:last-of-type:hover {
		cursor: not-allowed; }

	&--clear {
		background-color: var(--color-white);
		padding: 0;
		color: var(--color-dark);
		padding: 8px; } }
