.menu-item, .creatable-input {
  font-size: 16px;
  color: #000; }

.menu-item:hover {
  background-color: #DEDBFE; }

.creatable-input:not([disabled]) {
  font-weight: 500;
  color: #393946 !important;

  &:hover,
  &:focus {
    background-color: unset; } }

.creatable-input::placeholder {
  font-weight: 500;
  color: #393946 !important;
  opacity: 1 !important; }
.creatable-input::-webkit-input-placeholder {
  font-weight: 500;
  color: #393946 !important;
  opacity: 1 !important; }
.creatable-input:-ms-input-placeholder {
  font-weight: 500;
  color: #393946 !important;
  opacity: 1 !important; }
.creatable-input::-ms-input-placeholder {
  font-weight: 500;
  color: #393946 !important;
  opacity: 1 !important; }

.help-text {
  line-height: 16px; }
