.TagItem {
	display: inline-flex;
	flex-flow: row;
	align-items: center;
	font-size: 1.4rem;
	padding: 0 1.5rem;
	min-height: 3rem;
	position: relative;
	overflow: hidden;
	margin: .3rem;
	border-radius: 10rem;
	transition: all .2s;
	color: var(--color-white);
	cursor: pointer;
	font-weight: 400;

	& svg path {
		fill: var(--color-white); }

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 1rem;
		transform: rotate(45deg);

		& svg {
			height: 1.5rem;
			width: 1rem;
			stroke-width: .1rem;
			stroke: var(--color-white); }

		&--selected {
			transform: rotate(90deg);

			& svg {
				height: 1.5rem;
				width: 1rem;
				stroke-width: .1rem;
				stroke: #461544; } } } }
