.InteractionList {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: absolute;
	overflow-y: auto;
	overflow-x: hidden;

	&__empty {
		display: grid;
		gap: 2rem;
		justify-content: center;
		color: var(--color-dark);
		text-align: center;
		margin: 2rem 0 0 0;

		&__info {
			& p:first-child {
				font-size: 18px !important;
				margin-bottom: 1rem;
				font-weight: 600; }
			& p {
				font-weight: 400;
				line-height: 21px;
				max-width: 90%;
				margin: auto; } }

		&__image {
			display: flex;
			align-items: center;
			justify-content: center;
			justify-self: center;
			& svg {
				width: 200px; } } }

	&__section {
		&:last-child {
			margin-bottom: 10px; }

		&__status {
			display: flex;
			width: 2rem;
			align-items: center;
			justify-content: center; } } }
