.BlockedAccess {
	height: 100vh;
	width: 100vw;
	z-index: 200;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-family: Arial, sans-serif;

	&__content {
		display: flex;
		padding: 20px;
		border-radius: 10px;
		gap: 16px; }

	&__image {
		margin-bottom: 20px; }

	&__text {
		h1 {
			font-size: 32px !important;
			margin-bottom: 10px;
			font-weight: 600; }

		p {
			font-size: 16px;
			margin: 0; } } }
