.AgentContactItem {
	display: grid;
	grid-template-columns: min-content 1fr;
	gap: 1.5rem;
	padding: 1rem 2rem;
	transition: all .2s;
	cursor: pointer;
	align-items: center;


	&:hover {
		background-color: var(--color-light-grey);
		transition: all .2s; }


	&__avatar {
		height: 5rem;
		width: 5rem;
		position: relative;

		& > svg {
			position: absolute;
			height: 2rem;
			width: 2rem;
			right: -.1rem;
			bottom: -.1rem;
			z-index: 10;
			box-shadow: var(--shadow-light); } }


	&__header {
		display: grid;
		color: var(--color-dark);
		font-size: 1.1rem;
		margin-bottom: .5rem;

		& p {
			color: var(--color-dark);
			font-size: 1.3rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap; }

		& div:last-of-type {
			display: grid;
			grid-auto-flow: column;
			gap: .5rem;
			align-items: center; } }


	&__origin {
		& svg {
			height: .9rem; }

		&--sent path {
			fill: var(--color-red); }

		&--incoming path {
			fill: var(--color-green); } }


	&__info {
		display: grid;
		font-size: 1.2rem;
		color: var(--color-dark); } }
