.TransferItem {
	display: flex;
	padding: 1rem;
	border-radius: 3px;
	margin: 2px 0;
	background-color: #FFF;
	justify-content: space-between;

	& button {
		width: 100px; }

	&__wrapper {
		display: flex;
		align-items: center; }

	&__info {
		display: flex;
		justify-content: center;
		flex-flow: column;
		&--withStatus {
			width: 200px; }
		& p:first-of-type {
			font-size: 1.5rem;
			color: var(--color-dark);
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap; }
		& p:last-of-type {
			font-size: 1.3rem;
			font-weight: 400; } }

	&__avatar {
		width: 4rem;
		height: 4rem;
		margin-right: 2rem;

		& .AvatarName, & .AvatarQuantity, & .AvatarName::before, & .AvatarNameList::before {
			border: var(--color-white) 1px solid; } } }
