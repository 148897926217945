.AltuWhatsAppTemplates {
	display: grid;
	grid-template-rows: min-content;
	gap: 2rem;

	&__header {
		& .Text {
			&:first-child {
				color: var(--color-dark); }

			&:nth-child(2) {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap; } } }

	&__form {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: 2fr 1fr;
		justify-content: center;
		gap: 2rem;
		height: 12vh;

		&__input {
			padding-top: 1rem;
			padding-bottom: 1rem; } }

	& .Button {
		justify-self: center; } }
