.Modal {
	position: relative;
	z-index: 120;

	&[data-size="sm"] {
		min-width: 24vw; }

	&[data-size="md"] {
		min-width: 30vw; }

	&[data-size="bg"] {
		width: 40vw;
		max-width: 500px; }

	&[data-size="half-screen"] {
		width: 50vw;
		height: 100vh;
		position: absolute;
		z-index: 120;
		right: 0; }

	&__close {
		position: absolute;
		display: flex;
		width: 2rem;
		height: 2rem;
		top: 2rem;
		right: 2rem;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 120;

		& svg {
			height: 1.8rem;
			width: 1.8rem; } } }

@media only screen and (max-width:800px) {
	.Modal {
		width: 100% !important;
		height: 100%; }

	.Modal .Card {
		border-radius: 0;
		height: 100%; } }
