.Tooltip {
    position: relative;
    display: block;
    z-index: 10;
    overflow: visible;

    &:hover > .Tooltip__body {
        visibility: visible; }

    &__body {
        width: max-content;
        padding: 6px 7px 6px;
        position: absolute;
        visibility: hidden;
        background-color: var(--color-dark);
        border-radius: 3px;
        transition: all .1s;
        color: var(--color-white);
        box-shadow: 0 0 12px rgba(10, 10, 10, 0.12);
        max-width: 20rem;
        margin-top: 1rem;
        font-size: 1.4rem;
        text-align: center;
        line-height: 20px;

        &::before {
            content: '';
            width: 1rem;
            height: 1rem;
            background-color: var(--color-dark);
            position: absolute;
            top: -.5rem;
            transform: rotate(45deg); }

        &--center {
            left: 50%;
            transform: translateX(-50%);

            &::before {
                left: 50%;
                transform: translateX(-50%) rotate(45deg); } }

        &--top {
            left: 50%;
            transform: translateX(-50%);
            top: -45px;

            &::before {
                top: 27px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg); } }

        &--right {
            left: 0;

            &::before {
                left: .4rem; } }

        &--left {
            right: 0;

            &::before {
                right: .4rem; } } } }
