.ToggleNav {
	display: grid;
	grid-auto-flow: column;
	width: 100%;
	cursor: pointer;
	transition: all .2s;
	font-weight: 600;

	&-responsive {
		border-radius: 0;
		box-shadow: none;
		border-bottom: 1px solid var(--color-light); }

	&__count {
		width: 2.8rem !important; }

	&__item {
		display: grid;
		grid-auto-flow: column;
		gap: .5rem;
		align-items: center;
		justify-content: center;
		padding: 1rem .5rem;
		position: relative;
		white-space: nowrap;
		color: var(--color-dark);
		border-bottom: 2px solid var(--color-grey);

		&:hover {
			color: var(--color-primary); }

		&--active {
			transition: all .2s;
			color: var(--color-primary);
			border-bottom: 2px solid var(--color-primary); }

		&--info .ToggleNav__item__info {
			display: flex; }

		&--notify {
			animation: flashing 2s infinite;
			transition: all; } }

	& svg {
		height: 1rem;
		width: 1rem;

		& path {
			fill: var(--color-white); } } }

@keyframes flashing {
	0% {
		color: var(--color-primary); }
	50% {
		color: var(--color-light-grey);
		color: var(--color-dark); }
	100% {
		color: var(--color-primary); } }
