.InternalMessageBubble {
	display: flex;
	grid-auto-flow: row;
	flex-direction: column;
	border-radius: 1rem;
	color: var(--color-dark);
	margin: 1rem auto;
	position: relative;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	word-break: break-word;
	font-weight: 500;
	min-width: 45rem;
	max-width: 85%;

	border: 1px solid #FFD5B7;
	background: #FFEBDC;

	@media only screen and (max-width: 1250px) {
		max-width: 100%;
		min-width: 20rem; }

	&--isChatHistory {
		min-width: 28rem; }

	& .ReferenceContent {
		background-color: #FFD5B7;
		color: var(--color-dark); }
	&__header {
		height: 36px;
		border-radius: 1rem 1rem 0 0;
		background: linear-gradient(0deg, #FFD5B7 0%, #FFD5B7 100%), #121212;
		color: #CF620D;
		font-size: 16px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px;
		&__title {
			& svg {
				margin-bottom: -1px;
				margin-right: 8px;
				& path {
					fill: #CF620D; } } }
		&__time {
			font-weight: 400; } }
	&__footer {
		height: 57px;
		padding: 0 16px;
		border-top: 1px solid #FFD5B7;
		display: flex;
		align-items: center;
		gap: 2rem;
		justify-content: space-between;
		&__button {
			color: #2c4fd8;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			cursor: pointer;
			& svg {
				margin-bottom: -1px;
				margin-left: 8px;
				& path {
					fill: #2c4fd8; } } } }

	&__content {
		padding: 12px; }

	&__info {
		margin-top: 1rem;
		p {
			color: var(--color-white);
			font-size: 1.6rem; } }

	& > p {
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto; }

	&__info {
		display: flex;
		flex-direction: row;
		align-content: flex-end;
		gap: .4rem;
		min-width: 3.55rem;
		justify-content: flex-end;
		flex-shrink: 0; }

	&__content {
		padding: 12px;
		font-size: 14px;
		white-space: pre-wrap; } }
