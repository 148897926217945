.CircleDisplay {
	display: flex;
	align-items: center;
	justify-content: center;
	content: '';
	min-width: 1.8rem;
	height: 1.8rem;
	border-radius: 1rem;
	color: var(--color-white);
	font-weight: 600;
	font-size: 12px;
	padding-top: 1px;

	&--green {
		background-color: var(--color-green); }

	&--red {
		background-color: var(--color-red); }

	&--grey {
		background-color: var(--color-grey);
		color: var(--color-dark); }

	&--blue {
		background-color: var(--color-blue); }

	&--white {
		background-color: var(--color-white); }

	&--primary {
		background-color: var(--color-primary);
		color: var(--color-white); }

	&--small {
		height: .8rem;
		width: .8rem; }

	&--animate {
		animation: fading 1s infinite; } }

@keyframes fading {
	0% {
		opacity: 1; }
	50% {
		opacity: .2; }
	100% {
		opacity: 1; } }
