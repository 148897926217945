.css-1u9des2-indicatorSeparator {
	display: none; }
.css-t3ipsp-control {
	border-color: var(--color-primary) !important;
	box-shadow: 0 0 1px var(--color-primary) !important;
	&:hover {
		box-shadow: 0 0 1px var(--color-primary) !important;
		border-color: var(--color-primary) !important; } }
.css-tr4s17-option {
	background-color: #F1F5F9 !important;
	font-weight: 600 !important;
	color: #0F172A !important;
	& label {
		font-weight: 600 !important; }
	&:hover {
		background-color: #F1F5F9 !important; } }
.css-d7l1ni-option {
	background-color: #F1F5F9 !important; }
.css-wsp0cs-MultiValueGeneric {
	font-size: 14px !important;
	max-width: 105px; }
.css-1jqq78o-placeholder {
	color: #0F172A !important;
	font-size: 16px !important;
	font-weight: 400 !important; }
.css-13cymwt-control {
	border-radius: 2px !important;
	border: 1px solid var(--color-grey) !important;
	height: 39px !important; }
.css-1dimb5e-singleValue {
	color: #0F172A !important;
	font-size: 16px !important;
	font-weight: 400 !important; }
.css-1fdsijx-ValueContainer {
	display: flex !important; }
.isMultiple {
	background-color: transparent !important;
	& label {
		font-weight: 200 !important; } }

.MultiSelect {
	margin-right: 15px;
	&__wrapper {
		&__icon {
			margin: 0 6px 0 6px;
			display: flex;
			align-items: center;
			& svg {
				height: 17px; } }
		&__option {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 200;
			& input {
				margin-right: 8px;
				accent-color: var(--color-primary);
				margin-top: -2px; } }

		&__options {
			margin-bottom: 60px; }

		&__length-info {
			display: flex;
			min-width: 0;
			background-color: hsl(0, 0%, 90%);
			border-radius: 2px;
			margin: 2px;
			box-sizing: border-box;
			height: 29px;
			justify-content: center;
			align-items: center;
			padding: 0 5px;
			font-size: 14px; }

		&__footer {
			width: 100%;
			position: absolute;
			bottom: 0;
			height: 62px;
			display: flex;
			justify-content: center;
			align-items: center;
			& button {
				height: 40px;
				width: 100%;
				margin: 0 10px;
				display: flex; } } } }
