.NavBar {
	height: 64px;
	display: grid;
	grid-auto-flow: column;
	align-content: center;
	justify-content: space-between;
	background-color: #353535;
	z-index: 101;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

	& > .Logo {
		display: none; }

	&__breakStatus {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&--time {
			display: flex;
			gap: 4px;
			font-size: 1.6rem; }

		&--info {
			display: flex;
			gap: 8px;
			font-size: 1.6rem; }

		&--selected {
			display: flex;
			justify-content: space-between;
			background: hsla(0,0%,100%,.16);
			padding: 4px 10px;
			border-radius: 8px;
			margin-bottom: 2px; }

		&--action {
			display: flex;
			align-self: center;
			cursor: pointer;

			& > svg {
				height: 1.8rem;

				& > path {
					fill: var(--color-white); } } } }

	&__agentActions {
		display: grid;
		gap: 1rem; }

	&__settings {
		height: 100%;
		display: flex;
		align-items: center;
		&__button {
			cursor: pointer;
			border-radius: 4px;
			padding: 0 1.2rem;
			height: 48px;
			display: flex;
			align-items: center;
			transition: all .3s;
			&:hover {
				background: hsla(0,0%,100%,.16); }
			&--active {
				background: hsla(0,0%,100%,.16); }
			& svg {
				width: 2rem; } }
		& .NavbarDropDown {
			right: 9.5rem;
			@media only screen and (max-width: 600px) {
				right: 1rem; } }
		& path {
			fill: var(--color-white) !important; } }

	&__user {
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		padding: 8px;
		border-radius: 4px;
		cursor: pointer;
		transition: all .3s;
		&--active {
			background: hsla(0,0%,100%,.16);
			& .NavBar__options {
				transform: rotate(180deg); } }
		&__info {
			display: flex;
			align-items: center;
			gap: 1.5rem;
			font-size: 1.6rem;
			line-height: 2.4rem;
			font-weight: 500;
			margin-bottom: 1.6rem;
			& span {
				font-size: 1.4rem !important;
				text-transform: capitalize; }

			& > span:last-of-type {
				font-weight: bold; } }

		& .AvatarName {
			width: 3.6rem;
			height: 3.6rem;

			&::before {
				border: var(--color-white) 1px solid; } }

		&:hover {


			& .NavbarDropDown {
				display: flex;
				flex-flow: column; } } }

	&__actions {
		display: grid;
		grid-auto-flow: column;
		margin-right: 1.5rem;
		gap: 0.5rem;
		position: relative;

		& div:nth-child(4) > svg {
			transform: translateX(.38rem); } }

	&__options {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4rem;
		height: 4rem;
		cursor: pointer;
		outline: none;
		border-radius: 50%;
		transition: all .2s;

		& path {
			fill: var(--color-primary) !important; }

		& svg {
			width: 1.4rem; } }

	&__dialButton {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4rem;
		outline: none;
		cursor: not-allowed;

		& path {
			fill: var(--color-grey) !important; }

		&--active {
			cursor: pointer;

			& path {
				fill: var(--color-blue) !important; } } }

	&__helpButton {
		display: flex;
		align-items: center;
		height: 100%;
		outline: none;
		cursor: pointer;
		&__button {
			border-radius: 4px;
			padding: 0 1.2rem;
			height: 48px;
			display: flex;
			align-items: center;
			&:hover {
				background: hsla(0,0%,100%,.16); } }
		&--active {
			background: hsla(0,0%,100%,.16); }
		& path {
			fill: var(--color-white) !important; } }

	&__navigation {
		display: grid;
		grid-auto-flow: column;

		&__wrapper {
			display: grid;

			& .Backdrop {
				display: none; } }

		&__icon {
			display: none;
			justify-content: center;
			height: 100%;
			padding: 1rem;
			cursor: pointer;
			outline: none;

			& svg {
				height: 2.5rem;
				width: 2.5rem; } } }

	&__border {
        content: '';
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, #2C4FD8, #E30781, #A14EDA);
        position: fixed;
        z-index: 9;
        left: 0;
        top: 63px; }

	.NavBarItem {
		&__icon {
			display: flex;
			& svg {
				height: 1.4rem;
				width: 1.4rem; } }
		& path {
			fill: var(--color-white) !important; } } }

@media only screen and (max-width: 800px) {
	.NavBar {
		grid-auto-columns: 1fr;

		& > .Logo {
			display: grid;

			& > .Logo > img {
				height: 2.5rem; } }

		&__navigation {
			display: none;

			& .Logo, & .NavBarItem {
				display: none; }

			&__icon {
				display: flex; }

			&__wrapper {
				justify-content: flex-start;
				align-self: center; } }

		&__user__info {
			display: none; }

		&--responsiveMenu {

			& .NavBarItem {
				display: flex; }

			& .NavBar__navigation {
				display: flex;
				flex-flow: column;
				position: absolute;
				width: 100%;
				top: 6rem;
				background-color: #353535;
				border-bottom: 2px solid var(--color-light);
				z-index: 110 !important;
				&__icon {
					& path {
						fill: var(--color-white) !important; } } }


			& .Backdrop {
				display: block; } }

		&__actions {
			justify-content: flex-end; } }

	.NavBarItem {
		justify-content: flex-start !important;
		padding-left: 1.5rem !important;
		font-weight: 600 !important; } }
