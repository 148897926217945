.Call {
	width: 100%;
	border-radius: 3px;
	overflow: hidden;


	&__controls {
		position: absolute;
		display: flex;
		align-items: flex-end;
		bottom: 0;
		width: 100%;
		height: 13.8rem;
		padding-bottom: 2rem;
		background-image: linear-gradient(to bottom, rgba(48, 58, 70, 0), #303a46); }


	&__cam {
		display: flex;
		justify-content: center;
		position: relative;
		background-color: var(--color-dark);

		&__video {
			width: 100%;
			height: 27.5rem;

			&--off {
				background-color: #000000; } }

		&--mini {
			position: absolute;
			top: 2rem;
			right: 2rem;
			background-color: var(--color-dark);
			border-radius: 3px;

			& > video {
				width: 10.5rem;
				height: 8rem; }

			&--off {
				background-color: #000000; }

			&__info {
				position: absolute;
				width: 100%;
				height: 100%;
				display: grid;
				grid-auto-flow: column;
				gap: 2rem;
				align-items: center;
				justify-content: center;

				& > svg {
					width: 1.8rem;

					& > path {
						fill: var(--color-white); } } } } } }
