.TaskBox {
	position: relative;
	display: grid;
	grid-template-rows: min-content 1fr;
	gap: 2rem;

	& > .CommonInput {
		display: none; }

	&__top {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: min-content 1fr min-content max-content;
		align-items: center;
		padding: 3rem 7rem 0 7rem;
		gap: 2rem;

		& > h1 {
			margin-right: 10rem;
			font-size: 2.35rem;
			font-weight: 500;
			color: var(--color-dark); } }

	&__actions {
		display: flex;

		& > .Button {
			height: 40px;
			display: block; } }

	&__newTask {
		width: auto;
		min-width: inherit;
		background-color: var(--color-green);
		font-size: 1.4rem;
		text-transform: none;
		padding: 0 2rem;
		letter-spacing: normal; }

	&__filter {
		display: flex;
		width: 3.8rem;
		height: 3.8rem;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		outline: none;
		border-radius: 50%;
		position: relative;

		&--selected::before {
			content: '';
			width: .8rem;
			height: .8rem;
			background-color: var(--color-blue);
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			border-radius: 50%; }

		& path {
			fill: var(--color-dark); }

		&_wrapper {
			position: relative;
			cursor: pointer;
			outline: none; } } }

@media only screen and (max-width: 800px) {
	.TaskBox {
		grid-template-rows: min-content min-content 1fr;
		padding: 0 2rem 0 2rem;

		& > .CommonInput {
			display: grid; }

		&__top {
			grid-template-columns: 1fr min-content max-content;
			padding: 3rem 0 0 0;

			& > .CommonInput {
				display: none; }

			& > h1 {
				margin-right: 0; } } } }
