.InteractionListTitle {
	display: flex;
	align-items: center;
	color: var(--color-dark);
	padding: 0 2rem;

	&__count {
		width: 18px;
		height: 18px;
		border-radius: 10px;
		background: #CBD5E1;
		color: var(--color-dark);
		font-size: 14px;
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 1rem; }

	& > p {
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-size: 18px;
		font-weight: 600; } }
