.is-fullwidth {
  width: 100%; }

.is-fullheight {
  height: 100%; }

.is-max-z {
  z-index: 999; }

.is-flex-0 {
  flex: 0 0; }

.is-flex-1 {
  flex: 1 1; }

.Header {
  gap: 1rem; }

.File {
  width: 75%;
  height: 75%; }

.Video,
.Image {
  max-height: 75%;
  max-width: 100%; }
