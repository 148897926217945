.FilterMultipleItem {
	display: grid;
	gap: 1rem;
	grid-auto-flow: column;
	grid-template-columns: min-content 1fr;
	margin: 1rem 0;
	padding: .5rem 1rem;
	outline: none;
	cursor: pointer;
	align-items: center;
	color: var(--color-dark); }
