.ReferenceContent {
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #E2E8F0;
	border-radius: 10px;
	&__wrapper {
		display: flex;
		padding: 12px;
		align-items: center;
		& svg {
			margin-right: 10px;
			width: 25px !important;
			height: 30px !important; }
		&__info {
			font-size: 14px;
			width: fit-content;
			&__name {
				font-weight: 600;
				margin-bottom: 5px;
				display: block; }
			&__content {
				display: flex;
				align-items: center;
				& p {
					overflow: hidden;
					width: 100%;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					font-weight: 400; } } } }
	&__preview {
		min-width: 100px;
		max-width: 120px;
		height: 100%;
		border-radius: 10px 10px 0 0;
		margin-left: 2rem;

		& img, & video {
			width: 100%;
			height: 100%;
			border-radius: 0 10px 10px 0;
			object-fit: cover;
			object-position: center; }
		& audio {
			visibility: none; } } }
