.ReplyMessage {
  width: 100%;
  max-height: 120px;
  display: flex;
  position: relative;
  border-top: .1rem solid var(--color-light-grey);
  background-color: var(--color-light);
  align-items: center;
  padding: 0 1rem;
  z-index: 11;

  & .Bubble {
    width: 100%; }

  &__message {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px) {
      width: 85%; }
    & .MessageImage, & .MessageVideo {
      width: 100%; }
    &--internal-message > .ReferenceContent {
      border: 1px solid #FFD5B7;
      background: #FFEBDC; } } }
