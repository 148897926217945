.VideoNotification {
	display: grid;
	grid-auto-flow: column;
	gap: .5rem;
	padding: .5rem;
	background-color: var(--color-red);
	border-radius: 5rem;
	color: var(--color-white) !important;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	width: 6rem;
	font-weight: 600;
	letter-spacing: .1rem;


	& > p {
		color: var(--color-white); } }
