.HistoryList {
	display: grid;
	grid-template-rows: min-content 1fr;
	height: calc(100vh - 215px);

	& > div > p {
		color: var(--color-dark);
		text-align: center; }

	&__wrapper {
		position: relative; }

	&__list {
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		position: absolute;
		overflow-y: auto;
		overflow-x: hidden; } }
