.ClientSearch {
	display: grid;
	grid-template-rows: min-content 1fr;
	gap: 1.3rem;
	margin-top: 1rem;

	&__wrapper {
		position: relative;
		border-radius: .3rem;
		overflow: hidden; }

	&__item {
		display: flex;
		flex-flow: column;
		overflow-y: auto; }

	&__actions {
		display: grid;
		grid-auto-flow: column;
		justify-content: space-between; }

	&__message {
		padding-left: 3rem; } }
