.container {
    width: 100%;
    position: relative;
    &:before,
    &:after {
        content: "";
        display: table; }

    &:after {
        clear: both; } }

.mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center; }

.pagination {
    margin: 16px 0px; }
