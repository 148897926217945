.MainBox {
	display: flex;
	flex-direction: column;
	width: 20vw;
	min-width: 35rem;
	height: 100%;
	gap: 1rem;
	border-right: .5px solid var(--color-grey-light);
	position: relative;
	border-right: 1px solid #CBD5E1;
	padding-top: 5px;
	background-color: #FFF;

	&__collapse {
		height: 2px;
		width: 100%;
		background-color: #E2E8F0;
		display: flex;
		justify-content: center;
		margin: 10px 0 5px 0;
		&__actived {
			transform: rotate(-180deg); }
		& div {
			background: #898B94;
			border-radius: 50%;
			height: 24px;
			width: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: -11px;
			cursor: pointer;

			& svg {
				margin-top: 1px; } } }
	&__interactions {
		height: -webkit-fill-available;
		position: relative; } }

.MainBoxActive__interactions {
	height: -webkit-fill-available; }
