.Pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__info {
        display: flex;
        align-items: center;
        color: #656771;
        font-size: 14px;
        & .Select {
            width: 80px;
            height: 40px;
            margin: 0 32px 0 8px;
            position: relative;
            &::after {
                width: 1px;
                height: 32px;
                position: absolute;
                right: -16px;
                top: 4px;
                content: '';
                background-color: #CBD5E1; } } }
    &__pages {
        display: flex;
        & .default {
            cursor: not-allowed;
            &:hover {
                border-color: none; } } } }
.page {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #CBD5E1;
    color: #2c4fd8;
    margin-right: 8px;
    user-select: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        border-color: #CBD5E1; }

    &.active {
        color: #fff;
        background-color: #2c4fd8;
        border: 1px solid #2c4fd8; }

    &.disable {
        cursor: not-allowed;
        opacity: .5;
        color: rgb(67, 67, 67);
        border-color: rgb(67, 67, 67); } }
