.DragAndDropFileUpload {
	width: 100%;
	position: absolute;
	background: #e9edef;
	z-index: 20;
	overflow: auto;
	bottom: -10px;
	left: 0;

	@media only screen and (max-width: 768px) {
		display: none; }

	& .dropzone {
		width: auto;
		height: calc(100vh - 102px);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 28px;
		color: #aaa7a7;
		font-weight: 100;
		border: dashed 2px #969696;
		padding: 10px;
		margin: 20px; }

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 20px 10px 20px;
		background-color: var(--color-white);
		& svg {
			width: 15px; }
		& button {
			background-color: var(--color-white);
			border: none;
			cursor: pointer; } }

	&__alert {
		padding: 8px 24px;
		font-size: 14px;
		font-weight: 400;
		height: fit-content;
		width: 100%;
		position: absolute;
		& i {
			font-size: 14px !important;
			margin-bottom: 2px; } }
	&__actions {
		height: 55px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-right: 7px;
		background-color: var(--color-white);
		position: absolute;
		bottom: 0px;
		& button {
			width: 200px;
			margin-left: 20px; } }
	&__wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.6rem;
		padding: 0;
		&__content {
			padding: 20px;
			max-width: 700px;
			&__file {
				margin: 20px 0;
				position: relative;
				display: flex;
				justify-content: center;
				box-shadow: 0 3px 12px rgba(11, 20, 26, .16);
				background-color: var(--color-white);
				&__default {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 25px;
					& svg {
						width: 3.5rem !important;
						height: 3.5rem !important;
						display: block; } }
				& p {
					color: var(--color-dark-grey);
					font-size: 1.8rem !important;
					margin-top: 15px; }
				& video, & img {
					width: 100%;
					max-width: 100%;
					min-height: 70px;
					max-height: 550px; }
				& audio {
					padding: 5px; }
				&__icon {
					position: absolute;
					right: 10px;
					top: 10px; }
				& a svg {
					position: relative;
					width: 2.8rem;
					height: 2.8rem;
					border-radius: .3rem;
					&:hover {
						& path {
							fill: #000; } }
					& path {
						transition: fill .5s ease;
						fill: var(--color-dark-grey); } } }
			&__actions {
				display: flex;
				justify-content: space-between;
				& button + button {
					margin-left: 20px; } } } } }

@media only screen and (max-width:800px) {
	.DragAndDropFileUpload {
		& .Modal {
			width: auto;
			height: auto;
			max-width: 95%; }
		&__wrapper {
			width: auto;
			height: auto;
			border-radius: 0.8rem !important;
			&__content {
				padding: 20px; } } } }
