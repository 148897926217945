.Button {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary);
	color: var(--color-white);
	border: none;
	outline: none;
	transition: all .2s;
	padding: 0 10px;
	min-width: 10rem;
	position: relative;
	width: 48%;
	height: 42px;
	font-size: 1.4rem;
	font-weight: 600;
	cursor: pointer;
	border: 1px solid transparent;

	&:hover {
		filter: brightness(.9); }

	&:focus {
		border: 1px solid var(--color-grey); }

	& img {
		width: 1.8rem;
		height: 1.8rem; }

	&--inactive {
		color: var(--color-dark);
		background-color: var(--color-light-grey);
		cursor: not-allowed;
		opacity: 0.5; }

	&--onlyText {
		display: flex;
		background-color: transparent;
		color: var(--color-blue);
		text-transform: initial;
		letter-spacing: normal;
		font-size: 1.4rem;
		padding: 0;
		width: auto;
		min-width: auto; }

	&--success {
		background-color: var(--color-green) !important;
		cursor: not-allowed; }

	&--secondary {
		background-color: transparent;
		border: 1px solid var(--color-dark);
		color: var(--color-dark); }

	&--secondary--dark {
		background-color: transparent;
		border: 1px solid #898b94;
		color: #d8d8df;

		&:hover {
				color: #d8d8df; } } }
