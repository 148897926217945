.DashboardBox {
	max-width: 100vw;
	background-color: #FFF;
	overflow-y: auto;

	&__tabs {
		display: flex;
		list-style: none;
		margin: 20px 0 30px 0;
		&__tab {
			padding: 2px 10px;
			font-size: 16px;
			font-weight: 600;
			border-bottom: 2px solid #CBD5E1;
			cursor: pointer;
			&--active {
				color: #2c4fd8;
				border-bottom: 2px solid #2c4fd8; } } }

	&__wrapper {
		&__filters {
			margin-bottom: 30px;
			display: flex;
			& .Select {
				margin-left: 15px; } }
		&__row {
			display: flex;
			gap: 2rem;
			border-bottom: 1px solid #CBD5E1;
			padding: 0 0 40px 0;
			margin-bottom: 40px;
			flex-wrap: wrap;
			&__table {
				width: 100%;
				&__title {
					font-size: 16px;
					font-weight: 600;
					line-height: 24px;
					display: flex;
					align-items: center;
					gap: 1rem;
					margin-bottom: 10px; } }
			&__content {
				display: flex;
				gap: 2rem;
				min-height: 100px;
				@media only screen and (max-width: 500px) {
					flex-direction: column; }
				&__column {
					min-width: 90px;
					display: flex;
					flex: auto;
					flex-direction: column;
					&__title {
						font-size: 16px;
						margin-bottom: 10px;
						font-weight: 100; }
					&__description {
						font-size: 30px;
						font-weight: 600;
						display: flex;
						align-items: center;

						&__status--online, &__status--preBreak, &__status--break, &__status--offline {
							width: 16px;
							height: 16px;
							display: block;
							border-radius: 50%;
							margin-right: 10px;
							position: relative; }
						&__status--online {
							background-color: var(--color-green); }
						&__status--preBreak {
							background-color: var(--color-yellow);
							background-image: url('../../../assets/images/svg/clock.svg');
							background-repeat: no-repeat;
							background-position: 6px center; }
						&__status--break {
							background-color: var(--color-red);
							background-image: url('../../../assets/images/svg/less.svg');
							background-repeat: no-repeat;
							background-position: center; }
						&__status--offline {
							background-color: var(--color-dark-grey);
							&:after {
								content: '';
								position: absolute;
								width: 10px;
								height: 10px;
								border-radius: 50%;
								bottom: 3px;
								right: 3px;
								z-index: 9;
								background-color: var(--color-white); } } } } } }

		&__nps {
			display: flex;
			gap: 75px;
			@media only screen and (max-width: 768px) {
				flex-direction: column;
				gap: 20px; }
			&__content {
				display: flex;
				gap: 35px;
				flex-wrap: wrap;
				&__item {
					&__title {
						font-size: 16px;
						font-weight: 200;
						margin-bottom: 10px;
						display: block; }
					&__percent {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 30px;
						font-family: Hind;
						font-weight: 600; } } } }

		&__bars {
			min-height: 90px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 10px;
			gap: 7px;
			&__bar {
				display: flex;
				align-items: center;
				gap: 16px;

				&:last-child {
					.DashboardBox__wrapper__bars__bar__percent {
						color: #B00020; } }
				&__title {
					width: 150px;
					justify-content: flex-end;
					font-size: 16px;
					font-weight: 600;
					display: flex; }
				&__percent {
					width: 210px;
					font-size: 16px;
					font-weight: 600;
					color: #7EB23C; } } }

		&__survey {
			height: 100%;
			min-height: 90px;
			display: flex;
			align-items: center;
			padding: 0 20px;
			justify-content: center;
			@media only screen and (max-width: 768px) {
				padding: 0; }
			&__stars {
				--percent: calc(var(--rating) / 5 * 100%);
				font-size: 50px;
				@media only screen and (max-width: 768px) {
					font-size: 30px; }
				&::before {
					content: '★★★★★';
					background: linear-gradient(90deg, #FF9720 var(--percent), var(--color-light-grey) var(--percent));
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent; } }
			&__rating {
				margin-left: 30px;
				font-size: 30px;
				font-weight: 600; } }

		&__metrics {
			&__title {
				font-size: 18px;
				font-weight: 600; }
			&__description {
				max-width: 460px;
				font-size: 16px;
				margin-top: 10px;
				font-weight: 200; } } }
	&__header {
		height: 80px;
		border-bottom: 2px solid #e6ebf1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 5rem;
		@media only screen and (max-width: 768px) {
			padding: 1rem; }
		& h2 {
			font-size: 24px;
			font-weight: 600;
			padding-top: 5px;
			@media only screen and (max-width: 768px) {
				font-size: 20px; } }
		& button {
			height: 40px;
			border: none;
			background-color: #2c4fd8;
			color: #fff;
			border-radius: 2px;
			padding: 8px 15px;
			font-size: 16px;
			font-weight: 600;
			cursor: pointer;
			transition: background-color ease .5s;

			&:hover {
				background-color: #561c57; }
			&:disabled {
				background-color: #666;
				cursor: progress; } } }
	&__content {
		padding: 2rem 4rem;
		@media only screen and (max-width: 768px) {
			padding: 1rem 2rem; } }
	&__card {
		box-shadow: 0 0 12px rgba(10, 10, 10, 0.12);

		&__list {
			display: grid;
			grid-auto-flow: column;
			gap: 3rem; }

		&__text {
			font-size: 3.5rem;
			font-weight: bold;
			text-align: center; }

		&__header {
			padding: 1rem 2rem 0 2rem;
			font-weight: 600;
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			& svg {
				width: 6px;
				margin: 0 10px 2px 10px;
				display: block; } }

		&__content {
			padding: 1rem 2rem 2rem 2rem;
			overflow-x: auto;
			&__wrapper {
				overflow: auto;
				max-height: 230px; }

			&__table {
				width: 100%;
				border-collapse: collapse;
				border-spacing: 0;

				& thead {
					& tr {
						& th {
							width: 15%;
							padding: 5px 10px;
							background-color: #e6ebf1;
							position: sticky;
							top: 0;
							z-index: 1;
							font-size: 12px;
							&:first-child {
								width: 25%;
								text-align: start; } } } }
				& > tbody {
					& tr {
						&:nth-child(even) {
							background-color: #f8f8f8; }
						& td {
							width: 15%;
							text-align: center;
							padding: 7px 10px;
							font-size: 11px;
							&:first-child {
								width: 25%;
								text-align: start; } } } } } } } }
