.ChatButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.4rem;
	height: 4rem;
	border-radius: 50%;
	position: relative;

	&__count {
		width: 15px;
		height: 15px;
		background-color: var(--color-primary);
		color: var(--color-white);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 2px; }

	&::before {
		content: '';
		position: absolute;
		border-radius: 50%;
		height: .8rem;
		width: .8rem;
		top: 0;
		right: 0; }

	& svg {
		height: 2rem;
		width: 2rem; }

	&--changed::before {
		background-color: var(--color-orange); }

	&--filled::before {
		background-color: var(--color-primary); }

	&--success::before {
		background-color: var(--color-green); }

	&--error::before {
		background-color: var(--color-red); }

	&--disabled {
		display: none; }

	&:hover:not(.ChatButton--disabled) {
		cursor: pointer;

		& > svg > path {
			fill: var(--color-primary); } }

	& > svg > path {
		fill: var(--color-dark); } }
