.AgentTasks__group {
	margin-bottom: 2rem;


	&__title {
		font-size: 1.6rem;
		font-weight: 500;
		color: var(--color-dark);
		border-bottom: 1px solid var(--color-light-grey);
		padding-bottom: 1rem; } }
