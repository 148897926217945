.SearchInput {
	position: relative;

	&__list {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		max-height: 15rem;
		border: 1px solid var(--color-grey);
		border-radius: 3px;
		background: var(--color-white);
		list-style: none;
		z-index: 1;
		overflow: auto;

		& > li {
			border-bottom: 1px solid var(--color-grey);

			&:last-child {
				border-bottom: none; }

			&:hover {
				background: var(--color-light-grey); }

			& > a {
				display: block;
				color: var(--color-dark);
				padding: 1.2rem 2rem;
				text-decoration: none; } } } }
