.DropDownItem {
	display: grid;
	grid-template-columns: 1fr min-content;
	font-size: 1.6rem;
	align-items: center;
	padding: .7rem 0.3rem;
	font-weight: 500;
	width: 100%;
	overflow: hidden;
	outline: none;
	cursor: pointer;
	border-radius: .4rem;
	margin: .5rem 0;
	padding: 1rem 0.3rem 1rem 0.6rem;

	&:first-of-type {}

	&:hover {
		background: hsla(0,0%,100%,.16); }

	& path {
		fill: var(--color-dark-grey) !important; }

	& svg {
		max-width: 2rem;
		max-height: 1.8rem; } }
