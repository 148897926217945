.MessageLocation {
	width: 45%;
	min-width: 21rem;
	max-width: 30rem;
	& a {
		text-decoration: none !important; }

	&__container {
		color: #8784a4;
		background-color: var(--color-grey);
		font-size: 7rem;
		justify-content: center;
		display: flex;
		align-items: center;
		padding: 2rem;
		border-radius: 5px;
		margin-bottom: 5px;
		transition: background-color ease .2s;
		&:hover {
			background-color: var(--color-light-grey-2); } }

	& img {
		width: 100%; } }
