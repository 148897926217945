.MessageInternal {
    display: flex;
    flex-direction: column;
    &--warning {
        color: var(--color-dark-grey-4);
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: -10px;
        text-align: center; }
    &__name {
        display: block;
        margin-bottom: 4px;
        font-weight: 600; }
    & .Linkify {
        font-weight: 400; } }
