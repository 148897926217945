.InputFile {
    display: flex;
    font-size: 1.5rem;

    &__label {
        cursor: pointer;
        padding: 8px 24px;
        background-color: var(--color-light-grey-3);
        border-radius: 8px 0 0 8px;

        &--rounded {
            border-radius: 8px; } }

    &__file {
        display: flex;
        border-radius: 0 8px 8px 0;
        background-color: var(--color-light-grey-1);
        padding: 8px 18px;
        gap: 12px;

        a {
            color: var(--color--black);
            text-decoration: none; }

        .Wrapper {
            cursor: pointer; }

        svg {
            vertical-align: middle;
            height: 16px;
            width: 16px; } }

    &__input {
        display: none; }

    &--disabled {
        .InputFile__label {
            cursor: not-allowed;
            opacity: 0.6; }

        .InputFile__file {
            .Wrapper {
                cursor: not-allowed;
                opacity: 0.6; } } } }
