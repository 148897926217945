.Bubble {
	display: flex;
	grid-auto-flow: row;
	flex-direction: column;
	border-radius: 1rem;
	color: var(--color-white);
	padding: 1rem 1.2rem .2rem 1.2rem;
	margin: 1rem 0;
	position: relative;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	word-break: break-word;
	font-weight: 500;

	&__info {
		margin-top: 1rem;
		p {
			color: var(--color-white);
			font-size: 1.6rem; } }

	&--isChatHistory {
		max-width: 95%; }

	&--timeOnBottom {
		flex-flow: column;

		.Bubble__content {
			border-radius: .8rem;
			overflow: hidden; } }

	& > p {
		overflow-wrap: break-word;
		word-wrap: break-word;
		hyphens: auto; }

	&__info {
		display: flex;
		flex-direction: row;
		align-content: flex-end;
		gap: .4rem;
		min-width: 3.55rem;
		justify-content: flex-end;
		flex-shrink: 0;

		& svg {
			width: 1rem;

			& path {
				fill: var(--color-white); } } }

	&--agent {
		background-color: #0073E6; }

	&--transfer {
		background-color: #F3F5F6;
		border: 1px solid #D8D8DF;
		gap: 5px;
		padding: 12px;
		color: var(--color-dark); }

	&__title {
		font-weight: 600;
		line-height: 22px;
		white-space: pre-wrap; }

	&--client {
		background-color: var(--color-light-grey);
		color: var(--color-dark);
		justify-self: start;

		& .Bubble__info {
			margin-left: -1rem;

			p {
				color: var(--color-dark-grey); } } }

	&--rejected {
		color: var(--color-dark);
		background-color: #e7cecf;
		border: 1px solid #b00020;

		& .Bubble__info {
			p {
				color: var(--color-dark); } } }

	&__content {
		font-size: 14px;
		white-space: pre-wrap; }

	&__referenced-message {
		& .ReferenceContent {
			background-color: #054FB9;
			color: var(--color-white);
			border-radius: .8rem;
			margin-bottom: .6rem;
			cursor: pointer; }

		.MessageAttachment__info {
			width: 8rem;
			padding-bottom: .5rem; }

		.Bubble--client {
			background-color: #054FB9;
			color: var(--color-white);
			max-width: 100%;
			padding: 0; }

		.referenced-message-sender {
			padding-left: 1rem;
			padding-right: 0rem;
			font-weight: 600;
			padding-top: 1rem;

			&__client {
				color: var(--color-white); }

			&__agent {
				color: var(--color-dark-green); } } }

	&__attachments {
		display: grid;
		grid-auto-flow: column;
		gap: 1rem;
		grid-auto-columns: min-content;

		&__item {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--color-white);
			border-radius: .5rem;
			padding: 1rem;

			& svg {
				height: 2rem;
				width: 2rem;

				& path {
					fill: var(--color-dark); } } } } }
