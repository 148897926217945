.ToggleNavBar {
	display: flex;
	flex-direction: row;
	width: 100%;
	cursor: pointer;
	transition: all .2s;
	font-weight: 600;
	margin-top: 1.4rem;
	margin-bottom: -1px;
	overflow-x: auto;
	scrollbar-width: none;
	scroll-behavior: smooth;

	&--vertical {
		height: fit-content;
		flex-direction: column;
		margin: 2.5rem 1rem;
		gap: 1rem;
		& .ToggleNavBar__item {
			border: none;

			&--active {
				border-radius: 4px; } } }
	&__count {
		width: 2.8rem !important; }

	&__item {
		width: 3rem;
		padding: .6rem;
		margin: 0 .5rem;
		position: relative;
		white-space: nowrap;
		color: var(--color-dark);
		border-bottom: 1px solid #CBD5E1;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			color: var(--color-primary); }

		&--active {
			transition: all .2s;
			border-bottom: 1px solid var(--color-primary);
			border-radius: 4px 4px 0px 0px;
			background: rgba(115, 38, 116, 0.10); }

		&--info .ToggleNavBar__item__info {
			display: flex; }

		&--notify {
			animation: flashing 2s infinite;
			transition: all; } }

	& svg {
		height: 1.8rem;
		width: 1.8rem;

		& path {
			fill: var(--color-black); } } }

@keyframes flashing {
	0% {
		color: var(--color-primary); }
	50% {
		color: var(--color-light-grey);
		color: var(--color-dark); }
	100% {
		color: var(--color-primary); } }
