.MainBoxHeader {
	display: grid;
	gap: 1.5rem;
	padding: 0 1.5rem;

	&__actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: .3rem; } }
