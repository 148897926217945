.wrapper {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0, 0.03);
	left: 0;
	top: 0; }

.NavbarDropDown {
	position: absolute !important;
	background-color: #353535;
	right: 0;
	right: 1rem;
	top: 7rem;
	box-shadow: var(--shadow);
	border-radius: .125rem;
	z-index: 110;
	transition: all .2s;
	width: 32rem;
	padding: 2.4rem 2.4rem 1.6rem 2.4rem;
	border-radius: 2px;

	&--header {
		padding: 0 0.3rem;
		border-bottom: 1px solid #656771; }

	&--footer {
		display: flex;
		justify-content: center;
		border-top: 1px solid #656771;
		padding-top: 16px;
		margin-top: 16px; }

	&__separator {
		border-bottom: 1px solid #656771;
		margin-bottom: 16px; }

	&__sectionName {
		display: block;
		font-weight: 400;
		font-size: 12px; }


	& .LanguageChooser {
		padding: 1rem 0;
		justify-content: space-around;
		border-top: 1px solid #656771; }

	& .Button {
		width: 90%; }

	& .DropDownItem:first-of-type path {
		fill: var(--color-white) !important;
		stroke: var(--color-dark-grey); } }
