.MessageAttachment__info {
	display: grid;
	grid-auto-flow: column;
	gap: .8rem;
	align-items: center;


	& a {
		display: flex; }


	& svg {
		position: relative;
		width: 2.2rem;
		height: 2.2rem;
		border-radius: .3rem;

		& path {
			fill: var(--color-white); } }


	&--client svg path {
		fill: var(--color-dark); }


	& p {
		font-size: 1.3rem; } }
