.History {
	display: grid;
	grid-template-rows: min-content 1fr;
	gap: 1rem;

	&__title {
		padding: 15px 5px 0 5px;
		margin: 0 15px; }

	& .CommonInput {
		padding: 0 2rem; } }
