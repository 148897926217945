.InitialLoader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	&__image {
		height: 20rem;
		width: 20rem;
		animation: expanding 2s infinite; } }

@keyframes expanding {
	0% {
		opacity: .4; }

	50% {
		opacity: 1; }

	100% {
		opacity: .4; } }
