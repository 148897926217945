.IconButton {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: .8rem;
	height: 4rem;
	width: 4rem;
	transition: all .2s;
	position: relative;
	z-index: 9;

	&:hover {
		cursor: pointer;
		opacity: .8;
		transition: all .2s;

		& .IconButton__info {
			display: block; } }

	& > svg {
		height: 1.8rem;

		& > path {
			fill: var(--color-white); } }

	&--grey {
		background-color: var(--color-grey); }

	&--red {
		background-color: var(--color-red); }

	&--green {
		background-color: var(--color-green); }

	&--blue {
		background-color: var(--color-blue); }

	&--white {
		background-color: var(--color-white);

		& svg path, & > svg > path {
			fill: var(--color-dark); }

		&:hover > svg path {
			fill: var(--color-primary);
			transition: all .2s; } }
	&--color-light {
		background-color: var(--color-light);
		& path {
			fill: var(--color-dark) !important; } }
	&--grey-light {
		background-color: var(--color-light-grey);

		& path {
			fill: var(--color-dark) !important; } }

	&--rounded {
		border-radius: 50%; } }
