.MessageAudio {
	width: 40%;
	min-width: 21rem;

	& audio {
		width: 100%;
		height: 50px;
		min-width: 13rem;
		outline: none; }

	audio::-webkit-media-controls-play-button {
	  background-color: var(--color-white); }

	audio::-webkit-media-controls-panel {
		height: 50px;
		background-color: var(--color-white);
		border-radius: 5px;
		transition: opacity 0.3s; }

	audio::-webkit-media-controls-play-button {
		-webkit-appearance: media-play-button;
		background-color: var(--color-white); } }
