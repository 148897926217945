.LanguageChooser {
	& span {
		margin: 5px 0 10px 3px;
		font-weight: 600;
		display: block; }
	&__locales {
		display: flex;
		flex-direction: column; }
	& .onlyFlags {
		flex-direction: row; }
	& label {
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		height: 30px;
		cursor: pointer;
		font-weight: 400;
		margin: 4px 0; } }
