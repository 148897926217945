.MessageTyping {
	display: flex;
	justify-content: flex-start;


	&__animation .dot {
		display: inline-block;
		width: .5rem;
		height: .5rem;
		border-radius: 50%;
		margin-right: 3px;
		background: var(--color-white);
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s; }

		&:nth-child(3) {
			animation-delay: -0.9s; } } }


@keyframes wave {
	0%, 60%, 100% {
		transform: initial; }
	30% {
		transform: translateY(-.6rem); } }
