.AgentTasks {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 3rem 7rem 3rem 7rem;


	&_wrapper {
		position: relative; }


	& > p {
		text-align: center;
		color: var(--color-dark);
		margin-top: 3rem; } }


@media only screen and (max-width: 800px) {
	.AgentTasks {
		padding: 3rem 0 3rem 0; } }
