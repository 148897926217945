.Attachment {
	display: grid;
	grid-auto-flow: column;
	background-color: var(--color-dark);
	font-size: 1.3rem;
	border-radius: .5rem;
	color: var(--color-white);
	margin-right: 1rem;
	overflow: hidden;
	font-weight: 600;

	& a {
		text-decoration: none;
		color: var(--color-white);
		padding: 1rem; }


	& div:last-of-type {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3rem;
		background-color: var(--color-dark);
		outline: none; }


	&:hover {
		cursor: pointer; }


	& svg {
		height: 1rem;
		width: 1rem;

		& path {
			fill: var(--color-white);
			stroke: var(--color-white); } } }
