.CallControls {
	width: 100%;
	display: grid;
	gap: 2rem;
	justify-items: center;
	color: var(--color-white);


	&__buttons {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: repeat(4, max-content);
		gap: .5rem;
		justify-items: center;
		align-items: center;

		& > div {
			width: 4.5rem;
			height: 4.5rem; } } }
