.WhatsappTemplates {
	width: 100%;
	height: 100%;

	text-align: start;
	&__expirationTime {
		margin-top: 1rem;
		&__select {
			margin-top: 3rem; }
		& p {
			&:nth-child(2) {
				margin-top: 1rem;
				font-weight: 400; } } }
	& .Card {
		padding: 1.5rem; }
	&__actions {
		justify-content: space-between;
		position: absolute;
		bottom: 20px;
		width: calc(100% - 40px);
		display: flex;
		background-color: #fff; }
	&__preview {
		margin-top: 20px;
		&__header, &__footer {
			font-size: 16px;
			color: var(--color-dark-grey-4);
			font-weight: 400;
			padding: 3px 16px;
			display: block; }
		&__content {
			width: 100%;
			min-height: 115px;
			border-radius: 6px;
			border: 1px solid #CBD5E1;
			background: #F1F5F9;
			padding: 16px;
			color: var(--color-dark-grey-4);
			font-size: 16px;
			font-weight: 400;
			line-height: 24px; } }
	&__fields {
		margin-top: 1rem;
		&__header {
			margin-bottom: 1.6rem;
			color: var(--color-dark);

			& > p:first-of-type {
				color: var(--color-dark);
				margin-bottom: .5rem; }
			& > p:nth-child(2) {
				font-weight: 400; } }

		&__content {
			& .CommonInput {
				gap: 0;
				margin-bottom: 2rem;
				& input {
					height: 48px;
					font-size: 16px;
					padding: 0 16px !important; } }
			& p {
				font-weight: 400 !important;
				font-size: 16px !important;
				color: var(--color-dark-grey-4) !important;
				padding-left: 1.6rem; } } }

	&__select {
		position: relative;
		&__header {
			font-size: 16px;
			height: 48px;
			width: 100%;
			border-radius: 6px;
			border: 1px solid #CBD5E1;
			padding: 16px;
			font-weight: 400;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			&--active {
				& svg {
					transform: rotate(180deg); } }
			&__selected {
				width: 95%;
				display: flex;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				& span {
					display: block;
					margin-right: 1rem;
					color: var(--color-dark-grey-4); } }
			& svg {
				width: 2rem;
				height: 2rem;
				transition: all .3s ease;
				& path {
					fill: #2c4fd8; } } }
		&__body {
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
			position: absolute;
			background-color: #fff;
			z-index: 99;
			width: 100%;
			&__overlay {
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0; } } }
	&__container {
		height: calc(100vh - 90px);
		display: flex;
		flex-direction: column;
		gap: 18px;
		overflow: auto;
		padding: 5px; }
	&__list {
		& .InfiniteList {
			max-height: calc(100vh - 300px); }
		&__search {
			padding: 1.6rem 1.6rem .5rem 1.6rem; }
		&__form {}
		&__item {
			color: var(--color-dark);
			padding: 1.2rem 1.6rem;
			font-weight: 400;
			border-bottom: 1px solid #CBD5E1;
			transition: all .3s ease;
			&__tooltip {
				background-color: #fff;
				position: absolute;
				box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
				width: 500px;
				z-index: 9;
				padding: 16px;
				font-size: 14px;
				color: #000;
				opacity: 0;
				transition: all .3s ease;
				visibility: hidden;
				left: 10px;
				top: 75px;
				&--last {
					top: auto;
					bottom: 75px;
					&::before {
						top: auto !important;
						bottom: -5px; } }
				&::before {
					content: '';
					width: 10px;
					height: 10px;
					background-color: #fff;
					position: absolute;
					z-index: 99;
					top: -5px;
					transform: rotate(45deg); } }
			&:hover {
				cursor: pointer;
				background-color: #F1F5F9;
				p {
					font-weight: 500; }
				& + .WhatsappTemplates__list__item__tooltip {
					opacity: 1;
					visibility: visible; } }

			& p {
				&:first-child {
					color: var(--color-dark-grey-4);
					margin-bottom: .3rem; }

				&:nth-child(2) {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap; } } } } }
