.EditAgent {
	display: grid;
	justify-items: center;
	gap: 2rem;

	& .Button {
		width: 100%; }

	&__user {
		display: grid;
		gap: 1rem;
		justify-items: center;
		font-size: 4rem;

		& .AvatarName {
			width: 10rem;
			height: 10rem; }

		&__info {
			text-align: center;

			& p:last-of-type {
				color: var(--color-white);
				text-transform: uppercase; } } }

	&__password {
		display: grid;
		gap: 1rem;
		width: 550px; }

	&__avatar {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 10rem;
		width: 10rem; } }

@media only screen and (max-width:800px) {
	.EditAgent {
		height: 100%;
		align-content: space-between;
		padding: 5rem 2rem 2rem 2rem;

		&__password {
			width: 100%;
			gap: 3rem; } } }
