.AgentHasNoActiveDepartmentModal {
	width: 500px;
	max-width: 100%;
	display: grid;
	align-items: center;
	grid-auto-rows: 1fr;
	font-size: 1.6rem;
	padding: 0;

	&__content {
		padding: 3rem;
		display: flex;
		flex-flow: column;
		justify-content: space-around;
		align-items: center;
		height: 25rem;
		font-weight: 500;

		& p {
			text-align: center;
			margin: 2rem 0; }

		& svg {
			height: 12rem;
			width: 12rem;
			animation: expanding 2s infinite; } } }

@media only screen and (max-width:800px) {
	.AgentHasNoActiveDepartmentModal {
		width: auto;
		height: auto;
		grid-template-rows: 1fr min-content; } }
