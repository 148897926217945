.RadioButton {
	width: 20px;
	height: 20px;
	margin: 3px 10px 3px 3px;
	transition: .3s;
	appearance: none;
	background: #fff;
	box-shadow: inset 0 0 0 0.4em #fff, 0 0 0 0.1em;
	border-radius: 50%;
	cursor: pointer;
	color: var(--color-primary);

	&:hover, &:checked {
      background: var(--color-primary);
      box-shadow: inset 0 0 0 .4em #fff, 0 0 0 2px; }

	&:checked {
      background: var(--color-primary);
      box-shadow: inset 0 0 0 .3em #fff, 0 0 0 2px; }

	&:focus {
		outline: 0; } }
