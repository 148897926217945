.Box {
	border-radius: 4px;
	border: 1px solid #CBD5E1;

	&--small {
		flex: 1;
		max-width: 550px;
		@media only screen and (max-width: 968px) {
			max-width: 100%; }
		@media only screen and (max-width: 500px) {
			min-width: 100%; } }

	&--medium {
		flex: 2;
		max-width: 800px;
		@media only screen and (max-width: 968px) {
			max-width: 100%; }
		@media only screen and (max-width: 500px) {
			min-width: 100%; } }
	&--big {
		flex: 3; }

	&__header {
		height: 40px;
		background: #F1F5F9;
		border-bottom: 1px solid #CBD5E1;
		border-radius: 4px 4px 0 0;
		font-size: 16px;
		font-weight: 600;
		padding: 0 2rem;
		display: flex;
		line-height: 16px;
		align-items: center; }
	&__body {
		height: calc(100% - 40px);
		min-height: 130px;
		padding: 2.8rem; } }
