.ErrorBoundary {
	display: grid;
	align-content: center;
	justify-items: center;
	gap: 5rem;
	width: 100vw;
	height: 100vh;
	color: #dddddd;
	background-color: #fafafa;
}

.ErrorBoundary > button {
	background-color: #666666;
	padding: 2rem;
	border-radius: .5rem;
	color: #ffffff;
	font-size: 1.5rem;
	font-family: 'Montserrat';
	font-weight: 500;
	transition: all .2s;
	outline: none;
	border: none;
}

.ErrorBoundary > button:hover {
	transform: scale(1.01);
	transition: all .2s;
	cursor: pointer;
}

.ErrorBoundary__image {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20rem;
	width: 20rem;
	background-color: #dddddd;
	border-radius: 50%;
}

.ErrorBoundary__image svg {
	width: 15rem;
	height: 15rem;
}

.ErrorBoundary__image path {
	fill: #ffffff;
}