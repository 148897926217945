.TaskCheckbox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.8rem;
	height: 2.8rem;
	border: 1px solid var(--color-light-grey);
	border-radius: 50%;
	background: #fff;
	cursor: pointer;


	& > svg {
		display: none;
		width: 1.3rem;
		height: 1.029rem;
		fill: var(--color-white);
		pointer-events: none; }


	&:hover {
		background: var(--color-grey); }


	&.TaskCheckbox--active {
		border-color: transparent;
		background: var(--color-green);

		& > svg {
			display: block; } } }




