.AddCustomer {
	display: grid;
	gap: 3rem;
	position: relative;

	&__info {
		display: grid;
		gap: 1rem;
		color: var(--color-grey);

		&__info > p:first-of-type {
			color: var(--color-dark);
			font-weight: 500; } }

	& svg {
		height: 1.5rem;
		width: 1.5rem; }

	&__form {
		display: grid;
		gap: 1.2rem;
		font-size: 1rem;
		max-height: 45rem;
		overflow-y: auto;

		& .Button {
			width: 100%; }

		&__item {
			display: grid;
			grid-template-columns: 1fr min-content;

			& svg {
				height: 2rem;
				width: 2rem; }

			& .IconButton {
				margin-left: 1rem;
				margin-right: .1rem;
				transform: translateY(3rem); } } } }

@media only screen and (max-width:800px) {
	.AddCustomer {
		width: 100%; } }
