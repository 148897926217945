.table {
    table-layout: auto;
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-radius: 2px 2px 0px 0px;
    border-spacing: 0px;
    font-size: 16px;
    font-weight: 200; }

.column {
    height: 40px;
    background: #F1F5F9;
    padding: 0 16px;
    user-select: none;
    font-weight: 600;
    border-bottom: 2px solid #CBD5E1;

    &:first-child {
        border-top-left-radius: 2px; }

    &:last-child {
        border-top-right-radius: 2px; }

    &.sortable {
        cursor: pointer; }

    & > .cell {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-top: 2px;
        > span {
            margin: 0 0 0 8px; } } }

.row {
    height: 60px;
    &:hover > td {
        &.dark {
            background: #262626; }

        &.light {
            background: #fafafa; } }

    & > .cell {
        padding: 16px;
        border-bottom: 2px solid #CBD5E1; } }
