.HasAnotherSession {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--color-white);
    padding: 0 50px;
    &__icon {
        width: 100px;
        height: 100px;

        svg path {
            fill: var(--color-red); } }

    &__message {
        padding-left: 30px; }

    &__title {
        color: var(--color-dark-grey-1);
        font-size: 50px; }

    &__content {
        color: var(--color-dark-grey-3);
        font-size: 30px;
        padding-top: 10px; } }
