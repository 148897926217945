.ActiveInteractionsModal {
	display: grid;
	align-items: center;
	grid-auto-rows: 1fr;
	font-size: 1.6rem;
	padding: 0;


	&__info {
		padding: 1.5rem;
		display: flex;
		flex-flow: column;
		justify-content: space-around;
		align-items: center;
		background-color: var(--color-light-grey);
		height: 23rem;
		color: var(--color-dark);
		font-weight: 600;

		& svg {
			height: 12rem;
			width: 12rem;
			animation: expanding 2s infinite; } }


	&__loader {
		display: grid;
		gap: 2rem;
		height: 100%;
		justify-content: space-around;
		align-items: center;
		color: var(--color-dark-grey);
		font-weight: 600;
		padding: 1.5rem;

		& .Button {
			width: 100%; } } }


@keyframes expanding {
	0% {
		transform: scale(1.05); }

	50% {
		transform: scale(1); }

	100% {
		transform: scale(1.05); } }

@media only screen and (max-width:800px) {
	.ActiveInteractionsModal {
		width: auto;
		height: auto;
		grid-template-rows: 1fr min-content; } }
