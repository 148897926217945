.AgentContacts {
	display: grid;
	grid-template-rows: min-content 1fr;
	gap: 2rem;

	& > p {
		font-size: 1.6rem;
		text-align: center;
		color: var(--color-dark);
		padding-bottom: 1rem; }

	&__customers {
		height: calc(100vh - 140px);
		top: 0;
		bottom: 0;
		right: 0rem;
		left: 0rem;
		position: absolute;
		overflow-y: auto;
		overflow-x: hidden;

		&__wrapper {
			position: relative; }

		& > p {
			text-align: center;
			font-size: 1.6rem;
			color: var(--color-dark); } }

	&__actions {
		display: grid;
		gap: 1rem;
		grid-template-columns: 1fr min-content;
		align-items: center;
		margin: 1.5rem 1.5rem 0 1.5rem; } }
