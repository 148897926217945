.disabled {
	color: #94A3B8 !important;
	cursor: not-allowed !important;
	transition: all 0.5s ease-in-out;
	& svg path {
		fill: #94A3B8 !important; } }
.Send {
	display: flex;
	height: 4rem;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: var(--color-primary);
	transition: all 0.5s ease-in-out;

	& svg {
		width: 4.4rem;
		height: 2rem;
		transform: translateX(.2rem);
		& path {
			transition: all 0.5s ease-in-out;
			fill: var(--color-primary); } }

	&:hover {
		cursor: pointer; } }
