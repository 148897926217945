.RatingBar {
	&__bar {
		height: 8px;
		background-color: #EEE;
		border-radius: 16px;
		position: relative;

		&__progress {
			width: 80%;
			content: '';
			position: absolute;
			height: 8px;
			background-color: #7EB23C;
			display: inline-block;
			border-radius: inherit;
			animation: load 5s 1s;
			top: 0; } } }

@keyframes load {
  0% {}
		width: 0%; }
