.InternalMessages {
	min-height: 36vh;
	position: relative;
	& .MessageList {
		background-color: #fff; }
	&__actions {
		width: 100%;
		margin-top: 20px;
		background-color: #fff;
		display: flex;
		justify-content: center;
		& button {
			width: 100%; } } }
