.AvatarNameList {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	transform: translateX(-1.9rem);

	& > div {
		width: 4rem;
		height: 4rem;
		margin: 0 -1.8rem 0 0; }

	&--status::before {
		content: '';
		position: absolute;
		width: 1.2rem;
		height: 1.2rem;
		border-radius: 50%;
		bottom: 0;
		right: 0;
		z-index: 3;
		border: .15rem solid var(--color-dark-grey);
		transform: translateX(1.9rem); }

	&--online::before {
		background-color: var(--color-green); }

	&--offline::before {
		background-color: var(--color-dark-grey); }

	&--connecting::before {
		background-color: var(--color-orange); }

	&--small {
		transform: translateX(-1.2rem);

		& > div {
			width: 3.5rem;
			height: 3.5rem;
			margin: 0 -1.3rem 0 0;
			border: 2px solid var(--color-white); } } }
