.SelectOptionsItem {
	padding: .5rem 1rem;
	outline: none;


	&:hover {
		opacity: 0.7; }


	& p {
		color: var(--color-white) !important; } }
