.HistoryItem {
	display: grid;
	grid-auto-flow: row;
	grid-auto-rows: min-content;
	padding: 1.5rem 2rem;
	gap: 1.3rem;
	cursor: pointer;
	transition: background-color .2s;
	background-color: var(--color-white);
	font-weight: 400;

	& + .HistoryItem {
		box-shadow: 0px -1px 0px 0px #CBD5E1; }

	&:not(.HistoryItem--selected):hover {
		background-color: rgba(115, 38, 116, 0.06); }

	&--selected {
		transition: all .2s;
		background-color: rgba(115, 38, 116, 0.12);
		border-left: 4px solid #2c4fd8;
		& .HistoryItem__arrow svg {
			transform: rotate(180deg); } }

	&__arrow {
		& svg {
			transition: all .2s;
			& path {
				fill: var(--color-primary); } } }

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center; }

	&__icon {
		display: flex;
		font-weight: 500;
		color: var(--color-dark-grey-4);
		align-items: center;
		gap: 0.5rem;
		& > svg, & > img {
			width: 14px;
			height: 14px; } } }

.History {
	&__chat {
		display: grid;
		position: relative;
		height: 50rem; }

	&__info {
		display: flex;
		flex-flow: column;
		width: 88%;
		&__protocol {
			font-weight: 500;
			& span {
				color: var(--color-dark-grey-4);
				font-weight: 400; } }
		&__department {
			font-weight: 500;
			& span {
				color: var(--color-dark-grey-4);
				font-weight: 400; } }
		& div:first-of-type {
			display: flex;
			justify-content: space-between; } } }

.HistoryList__item {
	&__note {
		padding: 1rem;
		a {
			font-size: 1.2rem; } }
	&__tags {
		padding: .5rem; } }
