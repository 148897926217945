.ClientSearchItem {
	display: grid;
	gap: 2rem;
	padding: 2rem;
	border-radius: .8rem;
	transition: all .2s;
	position: relative;
	transition: all .2s;
	margin: 0 0 1rem 0;
	border: 1px solid var(--color-light-grey); }

.ClientSearch {
	&__info {
		display: grid;
		gap: 1rem;

		&__additional {
			display: flex;
			justify-content: space-between; } } }
