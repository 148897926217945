.TaskForm {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	&__wrapper {
		position: relative;
		height: 100%; }

	&__actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 1rem;
		&__button {
			width: 50%; } }

	&__form {
		display: grid;
		gap: 1rem;
		padding: 0 2rem; }

	&__header {
		display: grid;
		grid-auto-flow: column;
		justify-content: space-between;
		align-items: center;
		padding: 2rem;

		& h2 {
			font-size: 1.6rem;
			font-weight: 500;
			color: var(--color-dark); }

		&__wrapper {
			font-weight: 400;
			&__title {
				margin-bottom: 5px; }
			&__required {
				color: var(--color-dark-grey-4);
				margin-top: 5px; } } } }
