.Attachment {
	&__label {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 4rem;
		width: 4.4rem;
		border-radius: 50%;

		.Tooltip {
			height: 4rem; }

		&--disabled {
			display: none; }

		&:hover:not(.Attachment__label--disabled) {
			cursor: pointer;

			& > svg > path {
				fill: var(--color-primary); } }

		& svg {
			width: 1.97rem;
			height: 4rem;

			& > path {
				fill: var(--color-dark); } } }

	&__input {
		opacity: 0;
		position: fixed;
		top: -10rem; } }
