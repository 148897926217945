.ClientForm {
	&__infos {
		display: grid;
		grid-auto-rows: min-content;
		position: relative;
		margin-top: 1rem;

		& .Button {
			width: 100; } }

	&__form {
		&__actions {
			display: flex;
			justify-content: space-between;
			margin-top: 3rem; }
		&__note {
			font-weight: 400;
			& > p {
				color: var(--color-dark-grey-4); }
			& .Textarea {
				width: 100%;
				height: 90px; } }
		&__item {
			display: grid;
			grid-template-columns: 1fr min-content;

			& svg {
				height: 2rem;
				width: 2rem; }

			& .IconButton {
				width: 3rem;
				height: 3rem;
				margin-left: -3.9rem;
				margin-top: 0.9rem; } } } }
