.BreakStatusModal {
    color: #000; }

.BreakStatus {
    display: flex;
    flex-flow: column;
    max-height: 50vh;
    gap: 16px;

    &__choice {
        display: flex;
        flex-flow: column;
        gap: 8px;

        &--item {
            display: flex;
            font-size: 1.6rem;
            gap: 4px;
            align-items: center;
            cursor: pointer;
            font-weight: 400;

            & > div {

                & > svg {
                    height: 1.8rem;

                    & > path {
                        fill: var(--color-black); } } } } }
    &__statusContainer {
        background-color: var(--color-white);
        padding: 8px;
        border-radius: 2px; }
    &__list {
        max-height: 30vh;
        margin-top: 16px;
        &--item {
            display: flex;
            gap: 8px;
            padding: 8px;
            border-radius: 3px;
            align-items: center;
            cursor: pointer;

            &-emote {
                font-size: 1.6rem; }

            &:hover {
                background: hsla(0,100%,0%,.13); }

            &-selected {
                margin-left: auto;
                padding: 0 8px;
                align-self: flex-end;

                & > svg {
                    height: 1.8rem;

                    & > path {
                        fill: var(--color-black); } } } } }

    &__button {
        width: auto;
        grid-auto-columns: auto;
        text-transform: none;
        padding: 14px; } }
