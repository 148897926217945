.ClientInfo {
	overflow-y: auto;
	overflow-x: hidden;
	&__main {
		&__container {
			padding: 15px 5px;
			margin: 0 15px;
			&__title > p:first-child {
				font-size: 18px !important;
				margin: 0 0 1.2rem 0; }
			&__title {
				display: flex;
				justify-content: space-between;
				&__actions {
					display: flex;

					& > div:first-child {
						margin: 0 1.5rem 0 0; }
					& div {
						width: 18px;
						cursor: pointer;
						& svg {
							margin-top: 5px;
							width: 18px;
							height: 20px;
							& path {
								transition: fill 0.3s; }
							&:hover {
								& svg path {
									fill: var(--color-primary); } } } } } } }
		&__interaction {
			padding: 15px 5px;
			margin: 0 15px;
			box-shadow: 0px 1px 0px 0px #CBD5E1;
			& > p:first-child {
				font-size: 18px !important;
				margin: 0 0 1.2rem 0; }
			&__row {
				font-weight: 400;
				display: flex;
				margin-bottom: 0.5rem;
				&__channel {
					display: flex;
					font-weight: 500;
					align-items: center;
					grid-gap: 0.5rem;
					gap: 0.5rem;
					& svg {
						width: 16px; } }
				& p:first-child {
					color: var(--color-dark-grey-4);
					margin-right: 2rem;
					font-weight: 400; }

				& p:last-child {
					font-weight: 500; } } } }
	&__user {
		display: grid;
		gap: 1rem;
		text-align: center;
		padding: 0 2rem;

		&__actions {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 2rem;

			& > div {
				&:first-child:hover {
					transform: scale(1.1);
					transition: all .2s; }

				&:last-child:hover {
					transform: scale(1.1);
					transition: all .2s; } }

			&--search > div:last-of-type {
				background-color: var(--color-dark-grey);

				& svg path {
					fill: var(--color-white);
					animation: all .2s; } }

			&--edit > div:first-of-type {
				background-color: var(--color-dark-grey);
				animation: all .2s;

				& svg path {
					fill: var(--color-white); } } }

		&__info {
			display: grid;
			gap: .5rem;
			min-width: 0;

			& p {
				&:first-child {
					font-weight: 700;
					text-transform: capitalize;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden; }

				&:last-child {
					color: var(--color-dark); } } } }

	&__action {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		background-color: var(--color-dark-grey);
		transition: all .2s;

		&:hover {
			cursor: pointer; }

		& > svg {
			&:first-of-type {
				height: 1.7rem; }

			&:first-of-type {
				height: 1.6rem; }

			& > path {
				fill: var(--color-grey);
				stroke: var(--color-grey); } } }

	&__avatar {
		width: 7rem;
		height: 7rem;
		font-size: 2rem; }

	&__activeInteractions {
		padding: 15px 5px;
		margin: 0 15px;
		box-shadow: 0px 1px 0px 0px #CBD5E1;
		&__actions {
			display: flex; } } }
