.Mask {
	display: grid;
	gap: .7rem;
	position: relative;
	color: var(--color-dark);

	&__wrapper {
		display: flex;
		align-items: center;

		&--not-editing {
			& > p {
				font-weight: 400;
				&:last-of-type {
					font-weight: 500; }
				&:first-of-type {
					width: auto;
					color: var(--color-dark-grey-4);
					margin-right: 2rem; } } }

		&--editing {
			& > p {
				font-weight: 400;
				&:first-of-type {
					color: var(--color-dark-grey-4);
					width: 90px; } } }

		& > input {
			height: 48px;
			font-size: 1.6rem;
			font-weight: 400;
			width: 100%;
			padding: .65rem 4.5rem .65rem 1rem;
			border: .1rem solid var(--color-grey);
			border-radius: .8rem;

			&:focus {
				border: 1px solid var(--color-primary); }

			&:disabled {
				color: var(--color-dark-grey) !important;
				background-color: var(--color-light-grey);
				border: var(--color-light-grey); } }

		& > textarea {
			font-size: 1.6rem;
			font-weight: 400;
			width: 100%;
			height: 10rem;
			color: var(--color-dark) !important;
			font-family: 'Hind' !important;
			padding: 1rem;
			transition: all .2s;
			border: .1rem solid var(--color-grey);
			border-radius: .8rem;
			resize: vertica;

			&:focus {
				border: 1px solid var(--color-primary); }

			&:disabled {
				color: var(--color-grey) !important;
				background-color: var(--color-light-grey);
				border: var(--color-light-grey); } }

		&--invalid > input {
			transition: all .2s;
			color: var(--color-red) !important;
			border: .1rem solid var(--color-red);

			&:focus {
				border: .1rem solid var(--color-red); } } }

	&__alert {
		height: 2rem;
		margin: -8px 0px 0px 75px;
		color: var(--color-red) !important;
		opacity: 0;
		&--show {
			opacity: 1; } } }
