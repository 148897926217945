.ActiveInteractions {
	display: grid;
	gap: 1rem;
	grid-auto-flow: column;
	justify-content: start;
	position: relative;

	&__actions {
		position: relative; }

	& > p {
		font-weight: 400;
		color: var(--color-dark-grey-4); } }

.ActiveInteraction__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	outline: none;
	cursor: pointer;
	border: 1px solid var(--color-dark);
	border-radius: 2px;
	padding: 0.4rem 1rem;
	transition: all 0.2s linear;
	& > p {
		line-height: 16px;
		margin-top: 2px;
		transition: all 0.2s linear; }
	& > svg {
		height: 16px;
		width: 16px;
		margin-right: 8px;
		& path {
			transition: all 0.2s linear; } }

	&:hover {
		border: 1px solid var(--color-primary);
		& > p {
			color: var(--color-primary); }
		& > svg path {
			fill: var(--color-primary); } }

	&--phone--inactive:first-of-type, &--conference--inactive {
		fill: var(--color-grey) !important;
		color: var(--color-grey);
		border: 1px solid var(--color-grey);
		cursor: not-allowed;
		&:hover {
			border: 1px solid var(--color-grey);
			& > p {
				color: var(--color-grey); }
			& > svg path {
				fill: var(--color-grey); } } }

	&--phone--inactive path:first-of-type {
		fill: var(--color-grey) !important; }

	&--conference--inactive > svg > path {
		fill: var(--color-grey) !important;
		& svg {
			height: 1.5rem;
			width: 1.5rem; } } }
