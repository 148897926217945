.Select {
	display: grid;
	gap: .7rem;
	position: relative;

	&__label {
		display: flex;
		width: calc(100% - 2rem);
		font-size: 1.4rem;
		color: var(--color-dark);
		padding: 0.5rem 0;
		pointer-events: none; }

	&__select {
		position: relative; }

	& select {
		font-size: 1.4rem;
		font-weight: 400;
		width: 100%;
		color: var(--color-dark) !important;
		font-family: "Hind" !important;
		outline: none;
		padding: .7rem 3rem .7rem 1.7rem;
		border: 1px solid var(--color-grey);
		border-radius: .6rem;
		background: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&:focus {
			border: 1px solid var(--color-primary); }

		&:hover {
			cursor: pointer; }

		&:hover path {
			fill: var(--color-dark); } }

	& svg {
		position: absolute;
		top: 45%;
		right: 1.5rem;
		width: 1.5rem;
		height: 1.5rem;
		margin-top: -0.5rem;
		pointer-events: none;
		stroke-width: .3rem;
		stroke: var(--color-grey); }

	& path {
		fill: var(--color-grey); } }
