.AvatarName {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	letter-spacing: -.1rem;
	position: relative;
	color: #393946;
	background-color: var(--color-white);

	&--withName {
		background-color: var(--color-white); }

	svg path {
		fill: var(--color-primary); }

	&__info {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		overflow: hidden;
		letter-spacing: .1rem;
		font-weight: 600;

		> img {
			width: 100%;
			height: 100%;
			object-fit: cover; }

		> svg {
			width: 40%;
			height: 40%; } }

	&--status:before {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		bottom: -2px;
		right: -8px;
		z-index: 3; }

	&--online:before {
		background-image: url('../../../assets/images/svg/online.svg');
		background-repeat: no-repeat;
		background-position: center; }

	&--offline:before {
		background-image: url('../../../assets/images/svg/unavailable.svg');
		background-color: var(--color-dark-grey); }

	&--connecting:before {
		background-color: var(--color-grey);
		background-image: url('../../../assets/images/svg/connection-offline.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover; }

	&--prepause:before {
		background-image: url('../../../assets/images/svg/pre-break.svg');
		background-repeat: no-repeat; }

	&--pause:before {
		background-image: url('../../../assets/images/svg/break.svg');
		background-repeat: no-repeat;
		background-position: center; } }
