.MessageWrapper {
	display: flex;
	align-items: flex-end;
	&:hover .MessageWrapper__reply {
		opacity: 1;
		transition: ease-in-out .5s;
		z-index: 999; }

	&--internal {
		justify-content: center;
		.Bubble {
			border: 1px solid #FFD5B7;
			background-color: #FFEBDC;
			color: var(--color-dark); } }

	&--transfer {
		justify-content: center; }

	&--agent {
		padding: 0 0 0 3.5rem;
		justify-content: flex-end; }

	&--client {
		padding: 0 6.5rem 0 0; }

	&--INT_ADMIN {
		justify-content: start; }

	&--INT_AGENT {
		justify-content: flex-end; }

	&__reply {
		opacity: 0;
		transition: ease-in-out .5s;
		height: 100%;
		transform: translateY(30%);
		margin: 0.5rem 0.5rem;
		& .IconButton {
			width: 3rem;
			height: 3rem; } }

	&__avatar {
		margin-left: .5rem;
		width: 3rem;
		height: 3rem;
		min-width: 3rem;
		min-height: 3rem; } }
