.Answers {
	display: grid;
	font-size: 1.3rem;
	border-radius: 3px;
	background-color: var(--color-light);
	border-top: 1px solid var(--color-light-grey);
	position: relative;
	z-index: 120;

	&__answers {
		max-height: 20rem;
		overflow-y: auto; }

	&__item {
		display: grid;
		grid-template-columns: 10rem 1fr;
		align-items: center;
		gap: 1rem;
		color: var(--color-dark);
		padding: 1rem;

		& p {
			display: flex;
			align-items: center; }

		& span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis; }

		&:hover {
			cursor: pointer;
			background-color: var(--color-grey); }

		& span:first-child {
			color: var(--color-blue); }

		& svg {
			width: 15px;
			margin-left: 10px; } }

	&__loader {
		display: flex;
		justify-content: center;

		& .Spinner {
			margin: 0 1rem; } } }
