.CallDuration {
	display: flex;
	background-color: #E4EEFB;
	height: 67px;
	width: 420px;
	border-radius: 8px;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	z-index: 1;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	&--info {
		display: flex;
		justify-content: space-between; }
	&--signal {
		margin-left: 36px; }
	&--title {
		font-size: 14px;
		font-weight: 600;
		margin-left: 8px;
		color: #393946;
		margin-right: 6px; }
	&--time {
		font-size: 14px;
		font-weight: 400;
		font-style: italic; }
	&--buttons {
		display: flex;
		gap: 20px;
		margin-right: 34px;
		background: transparent;
		button {
			background: transparent;
			border: none;
			padding: 0;
			margin: 0;
			cursor: pointer; } } }
