.FormInput {
	display: grid;
	gap: .2rem;
	position: relative;
	color: var(--color-dark);

	&__label {
		color: var(--color-dark-grey-4);
		font-weight: 400; }

	&__label--dark {
		color: var(--color-white);
		font-weight: 400; }

	& > input {
		font-size: 1.6rem;
		height: 48px;
		width: 100%;
		color: var(--color-dark);
		font-family: 'Hind' !important;
		outline: none;
		padding: .65rem 1rem .65rem 1rem;
		border: .1rem solid var(--color-grey);
		border-radius: .6rem;

		&:focus {
			border: 1px solid var(--color-primary); }

		&:disabled {
			color: var(--color-dark-grey) !important;
			background-color: var(--color-light-grey);
			border: var(--color-light-grey); } }

	& > textarea {
		font-size: 1.4rem;
		font-weight: 600;
		width: 100%;
		height: 10rem;
		color: var(--color-dark) !important;
		font-family: 'Hind' !important;
		outline: none;
		padding: 1rem;
		transition: all .2s;
		border: .1rem solid var(--color-grey);
		border-radius: .6rem;
		resize: vertica;

		&:focus {
			border: 1px solid var(--color-primary); }

		&:disabled {
			color: var(--color-grey) !important;
			background-color: var(--color-light-grey);
			border: var(--color-light-grey); } }

	&--invalid > input {
		transition: all .2s;
		color: var(--color-red) !important;
		border: .1rem solid var(--color-red);

		&:focus {
			border: .1rem solid var(--color-red); } }

	&__alert {
		color: var(--color-red) !important;
		opacity: 0;
		height: 1rem;

		&--show {
			opacity: 1; } } }
