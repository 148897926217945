.ConferenceUser {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	height: 8rem;
	width: 8rem;
	box-shadow: var(--shadow-light);
	border-radius: .5rem;


	& .AvatarName {
		width: 3.5rem;
		height: 3.5rem;
		margin-bottom: 1rem; } }
