.Logo {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: start;
	gap: .8rem;
	padding: 0 2.4rem;

	& > img {
		height: 32px; } }
