.TaskSidebarInfo {
	padding: 2rem;

	& > h3 {
		font-size: 1.3rem;
		font-weight: 500;
		color: var(--color-dark);
		margin-top: 0.5rem;
		margin-bottom: 1rem; }

	&__data {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 1rem;
		margin-bottom: 2rem;
		border-bottom: 1px solid var(--color-light-grey); }

	&__close {
		text-align: right;

		& > svg {
			width: 1rem;
			height: 1rem;
			fill: var(--color-dark);
			cursor: pointer; } }

	&__status {
		font-size: 1.2rem;
		font-weight: 500;
		border-radius: 2.5rem;
		width: 9.4rem;
		height: 2.7rem;
		text-align: center;
		line-height: 2.7rem;

		&.TaskSidebarInfo__status {
			&--opened {
				color: var(--color-dark);
				background: var(--color-grey); }

			&--finished {
				color: var(--color-green);
				background: var(--color-light-green); }

			&--expired {
				color: var(--color-red);
				background: var(--color-light-red); } } }

	&__edit {
		margin-top: 1.5rem;

		& > a {
			margin-left: 0.5rem;
			font-size: 1rem;
			color: var(--color-dark); }

		& > svg {
			width: 1.2rem;
			height: 1.2rem;
			vertical-align: middle;

			& path {
				fill: var(--color-dark); } } }

	&__actives {
		display: grid;
		gap: 1rem;
		border-bottom: 1px solid var(--color-light-grey);
		padding-bottom: 2rem;
		margin-bottom: 1rem;

		& p {
			font-size: 1.3rem;
			color: var(--color-dark); } } }

p.TaskSidebarInfo {
	&__description {
		font-size: 1.4rem;
		font-weight: 400;
		white-space: pre-wrap;
		max-height: 30rem;
		overflow: hidden;
		overflow-y: auto;
		margin-bottom: 1rem; }

	&__item {
		font-size: 1.4rem;
		font-weight: 400;
		color: var(--color-dark-grey);
		margin-bottom: 0.4rem; } }
