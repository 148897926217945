.Notifications {
	display: grid;
	gap: 1rem;
	position: fixed;
	top: 7rem;
	right: 6.4rem;
	z-index: 120; }


@media only screen and (max-width:800px) {
	.Notifications {
		left: 50%;
		transform: translateX(-50%);
		right: auto; } }

