.ClientMessage {
	cursor: pointer;
	padding: 1.5rem 2rem 1.5rem 1.6rem;
	position: relative;
	transition: background-color .3s;
	border-left: 4px solid var(--color-white);

	& + .ClientMessage {
		box-shadow: 0px -1px 0px 0px #CBD5E1; }
	&__wrapper {
		display: flex;
		flex-direction: column; }

	&:not(.ClientMessage--selected):hover {
		background-color: rgba(90, 76, 250, 0.06); }

	&--selected {
		background-color: rgba(90, 76, 250, 0.12);
		border-left: 4px solid #2c4fd8; }

	&__department {
		margin: -4px 0px;

		& p {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			color: var(--color-dark-grey); } }

	&__type {
		position: relative;
		width: 4.5rem;
		height: 4.5rem;

		&__missed svg > g > path:first-of-type {
			fill: var(--color-dark-grey) !important; }

		&__missed svg > #instagramLogo > #gradientCircle1,
		&__missed svg > #instagramLogo > #gradientCircle2 {
			fill: var(--color-dark-grey) !important; }

		& svg {
			width: 100%;
			height: 100%; }

		& img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: var(--color-white); } }

	&__pendingMessages {
		position: absolute;
		bottom: -0rem;
		right: -0rem; }

	&__message {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		min-width: 0; }

	@keyframes fading {
		0% {
			opacity: 1; }
		50% {
			opacity: .4; }
		100% {
			opacity: 1; } }

	&__sla-wrapper {
		border-top: 1px solid rgba(137, 139, 148, 0.35);
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		padding: 8px 0 0px 0;
		margin-top: 5px;
		&--animation {
			animation: fading 1.5s infinite; }

		&__content {
			position: relative;
			&:hover {
				.ClientMessage__sla-wrapper__content__popover {
					display: block; } }

			&__popover {
				width: 325px;
				padding: 15px 10px;
				border: 1px solid #D8D8DF;
				border-radius: 4px;
				position: absolute;
				background: var(--color-white);
				display: none;
				z-index: 99;
				left: -15px;

				&__title {
					padding-bottom: 10px;
					display: flex;
					font-weight: 600;
					font-size: 14px;
					& div {
						margin-left: 10px;
						font-weight: 500;
						border-radius: 24px;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 10px;
						color: var(--color-white); } }

				&__warning {
					font-size: 13px;
					font-weight: 400;
					line-height: 18px;
					display: flex;
					padding: 12px;
					background: #F1F5F9;
					box-shadow: 2px 0px 0px 0px var(--color-dark-grey-4) inset;
					& svg {
						width: 25px;
						height: 16px;
						margin-right: 8px; } }

				&__list {
					margin-top: 10px;
					&__item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-top: 10px;
						& span {
							display: flex;
							align-items: center;
							font-size: 14px; }
						& svg {
							width: 12px;
							margin-right: 5px; } } } } }

		&__alert {
			display: flex;
			align-items: center;
			font-weight: 600;
			& svg {
				width: 14px;
				margin: 1px 5px 4px 5px; } } }

	&__info {
		display: grid;
		grid-template-columns: 1fr min-content;
		gap: 2rem;

		&__source {
			display: flex;
			color: var(--color-dark-grey-4);
			font-weight: 500;
			align-items: center;
			gap: 0.5rem;
			margin-bottom: 6px;
			& svg, & img {
				width: 14px;
				height: 14px; }
			& p {
				line-height: 13px; } } }
	&__customer {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		font-weight: 500;
		margin-bottom: .3rem; }

	&__content {
		display: flex;
		justify-content: space-between;
		line-height: 18px;
		&__last-message {
			display: flex;
			width: 235px;
			& svg {
				min-width: 14px;
				min-height: 12px;
				margin: 2px 5px 0 0; }

			&__count {
				margin-right: .5rem;
				&--big {
					width: 2.8rem !important; } } }

		&__without-messages {
			border-radius: 6px;
			background: #CBD5E1;
			padding: 6px 15px;
			font-size: 14px;
			display: flex;
			align-items: center;
			& svg {
				margin-right: 5px; } }
		& p {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: 400; }

		&__tick {
			display: flex;
			height: 2rem;
			width: 2rem;
			align-items: center;
			justify-content: center;

			& svg {
				width: 1rem; }

			& path {
				fill: var(--color-dark-grey) !important; } } }

	&__from {
		display: flex;
		align-items: center;

		& svg {
			height: 1rem;
			width: 1rem;
			margin-right: .5rem; }

		&--agent path {
			fill: var(--color-red); }

		&--client path {
			fill: var(--color-green); } }

	&__action {
		height: 2.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 15px;
		top: 15px;
		transition: all .2s;
		font-size: 14px;
		font-weight: 500;
		border-radius: 2px;
		border: 1px solid #2c4fd8;
		color: #2c4fd8;
		background: var(--color-white);
		padding: 8px;

		&:hover {
			background: #2c4fd8;
			color: var(--color-white);
			& svg path {
				fill: var(--color-white); } }
		& svg {
			height: 1.4rem;
			margin-left: 10px;

			& path {
				fill: #2c4fd8; } }

		&--ringing {
			animation: ringing 1s infinite;

			& > svg {
				animation: expanding 1s infinite; } } } }

@keyframes expanding {
	0% {
		transform: scale(1.2); }

	50% {
		transform: scale(1); }

	100% {
		transform: scale(1.2); } }

@keyframes ringing {
	0% {
		border-color: var(--color-primary);
		opacity: 1; }

	50% {
		opacity: .5; }

	100% {
		border-color: var(--color-primary);
		opacity: 1; } }

@media only screen and (max-width: 800px) {
	.ClientMessage__action {
		width: 85px;

		& > svg {
			display: block;
			opacity: 1;
			width: 2.5rem;
			height: 2.5rem; } } }
