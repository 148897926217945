.ChatBox {
	display: grid;
	position: relative;
	height: 100%;
	grid-template-rows: min-content 1fr max-content;

	&__chatInfo {
		display: grid;
		background-color: var(--color-white); }

	&__chat {
		position: relative;

		&__warning {
			position: absolute;
			z-index: 9;
			background: var(--color-dark-grey-4);
			width: 100%;
			color: #fff;
			padding: 16px;
			font-size: 16px;
			line-height: 20px;
			font-weight: 400;
			display: flex;
			align-items: center; }

		&__warning-input {
			padding: 20px;
			background: #F1F5F9;
			border: solid 2px #CBD5E1;
			margin: 0 1rem 1rem 1rem;
			border-radius: 8px;
			height: 90px;
			color: var(--color-dark-grey-4);
			font-size: 16px;
			font-weight: 400;
			line-height: 21px; } } }
