.Dial {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
	justify-items: center;


	&__button {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		color: var(--color-dark);
		width: 100%;
		height: 5rem;
		border-radius: .5rem;
		outline: none;
		transition: all .2s;
		font-weight: 700;

		&:hover {
			cursor: pointer;
			transition: all .2s;
			transform: scale(1.1); }

		& span:first-of-type {
			font-size: 2rem; }

		& span:last-of-type {
			font-size: 1rem;
			color: var(--color-dark); } } }
