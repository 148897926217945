.Spinner {
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: .6rem solid var(--color-grey);
	border-right: .6rem solid var(--color-grey);
	border-bottom: .6rem solid var(--color-grey);
	border-left: .6rem solid var(--color-primary);
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	animation: spin .7s infinite linear;
	display: flex;
	justify-self: center;

	&, &:after {
		border-radius: 50%;
		width: 3rem;
		height: 3rem; }

	&__wrapper {
		display: grid;
		padding: 1rem; } }

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }
