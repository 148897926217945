.CallControl {
	display: grid;
	background-color: var(--color-white);
	border-radius: .5rem;
	margin-top: 2rem;
	gap: 2rem;
	transition: all .2s;
	z-index: 110;

	& p {
		text-align: center; }

	&__dial {
		display: grid;
		gap: 1rem;

		&__delete {
			cursor: pointer; }

		&__input {
			display: grid;
			grid-template-columns: 1fr min-content;
			gap: 1rem;
			border-bottom: 1px solid var(--color-dark);
			padding: 0 1rem 0 2rem;
			align-items: center;

			& input {
				font-size: 2.6rem !important;
				padding: 1rem 0;
				font-weight: 600; }

			& > input {
				background-color: transparent;
				width: 100%;
				border: none;
				outline: none;
				color: var(--color-dark);
				font-size: 2rem; }

			& svg {
				width: 2.2rem;

				&:hover {
					transition: all .2s;
					transform: scale(1.1); }

				& path {
					&:last-of-type {
						fill: var(--color-white); }

					&:first-of-type {
						fill: var(--color-dark); } } } } }

	&__actions {
		display: grid;
		grid-auto-flow: column;
		justify-content: space-around; }

	&__action {
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		cursor: pointer;
		transition: all .2s;

		&:hover {
			transform: scale(1.1);
			transition: all .2s; }

		& svg {
			width: 4.5rem;
			height: 4.5rem; }

		&__call {
			& path:first-of-type {
				fill: var(--color-grey) !important;

				&--active path:first-of-type {
					fill: var(--color-green) !important; } } }

		&__finish path:first-of-type {
			fill: var(--color-red) !important; }

		&__addToConf {
			background-color: var(--color-dark);
			border-radius: 50%;
			width: 4.5rem;
			height: 4.5rem;

			&--active {
				background-color: var(--color-dark) !important; } }

		&__conference {
			& svg > g > circle {
				fill: var(--color-grey); }

			&--active svg > g > circle {
				fill: var(--color-green); } } } }
