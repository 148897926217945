.InfiniteList {
	height: 100%;


	&--scroll-vertical {
		overflow-y: auto;
		overflow-x: hidden; }


	&--scroll-horizontal {
		overflow-x:auto {}
		overflow-y: hidden; }


	&--scroll-both {
		overflow:auto {} } }
