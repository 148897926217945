.MessageList {
	display: flex;
	flex-flow: column;
	grid-auto-rows: min-content;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 3rem 2rem 3rem 2rem;
	background-color: var(--color-light);

	@media only screen and (max-width: 768px) {
		width: 100vw; }

	& > * {
		margin-bottom: .2rem; }

	& .templateRejected {
		color: #800013;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		gap: 8px;

		> svg {
			width: 18px;
			height: 18px;

			> path {
				fill: #b00020; } } } }

.message-list {
	position: absolute;
	overflow-y: auto;
	height: -webkit-fill-available;
	padding: 3rem 1rem 3rem 2rem;
	background-color: var(--color-light);
	width: 100%;

	flex-direction: column-reverse; }

.new-messages {
	position: absolute;
	bottom: 15px;
	left: calc(50% - 84px);
	background: #353535;
	color: #fff;
	padding: 6px 15px;
	font-size: 14px;
	border-radius: 50px;
	cursor: pointer; }
