.Switch {
	outline: none;

	&__label {
		cursor: pointer;
		text-indent: -9999rem;
		width: 4rem;
		height: 2.4rem;
		background: var(--color-dark-grey-1);
		display: block;
		border-radius: 1.25rem;
		position: relative;
		padding: .7rem;

		&:after {
			content: '';
			position: absolute;
			display: flex;
			align-items: center;
			top: .40rem;
			left: .5rem;
			width: 1.6rem;
			height: 1.6rem;
			background: var(--color-white);
			border-radius: 1.12rem;
			transition: 0.3s; } }

	&--active .Switch__label {
		background: var(--color-primary);

		&:after {
			left: calc(97% - .4rem);
			transform: translate(-98%); } } }
